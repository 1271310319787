'use client'
import { rootStore } from '@store/index'
import { AllMembersIcon, UnassignedIcon } from '@touchpoints/icons'
import { useReaction } from '@touchpoints/mobx-hooks'
import { IOrganizationUser, ITask, ITeam, OrganizationUser } from '@touchpoints/requests'
import { useCustomFilterableInput } from '@touchpoints/ui'
import clsx from 'clsx'

import { Dropdown } from '@touchpoints/ui'
import Fuse from 'fuse.js'
import { observer } from 'mobx-react-lite'
import { PropsWithChildren, useEffect, useMemo, useRef, useState } from 'react'
import { HiUsers } from 'react-icons/hi'
import { assigneeFieldOptions as baseRoles } from '@components/blueprints/shared'
import { PiUserCircleGear } from 'react-icons/pi'
import { OrgUserAvatar } from '@components/shared/OrgUserAvatar'

const CustomDropdownItem = observer(
	({ children, onClick }: PropsWithChildren<{ onClick?: () => void }>) => {
		return (
			<div
				className="flex items-center space-x-2 p-1.5 truncate rounded-lg hover:bg-[#eceef0] m-1 cursor-pointer"
				onClick={onClick}
			>
				{children}
			</div>
		)
	}
)

type TaskAssigneeProps = {
	task?: ITask
	allMembers?: boolean
	unassigned?: boolean
	includeTeams?: boolean
	includeRoles?: boolean
	value?: string
	onChanged?: (assignedUserId: string) => void
	rightIcon?: React.ReactNode
	filterable?: boolean
	inputPlaceholder?: string
	groupByTeams?: boolean
}
export const TaskAssignee = observer(function ({
	task,
	value,
	allMembers,
	unassigned,
	includeTeams,
	onChanged,
	rightIcon,
	filterable,
	inputPlaceholder,
	includeRoles = false,
	groupByTeams = false,
}: TaskAssigneeProps) {
	const [open, setOpen] = useState(false)
	const [assigneeId, setAssigneeId] = useState(value ?? task?.assignedUserId ?? '')
	const [assignee, setAssignee] = useState<IOrganizationUser | undefined>(undefined)
	const ref = useRef<HTMLInputElement>(null)

	const baseOptions = useReaction(() =>
		rootStore.organizationUsers.usersForActiveOrg
			.filter((u) => u.status === 'active')
			.map((u) => ({
				value: u.id,
				label: u.firstName && u.lastName ? `${u.firstName} ${u.lastName}` : u.email,
			}))
			.sort((a, b) => a.label.localeCompare(b.label))
	)

	const baseOptionsByGroup = useReaction(
		() => [
			...(allMembers ? [{ value: 'none', label: 'All Members' }] : []),
			...(!assigneeId || unassigned ? [{ value: 'unassigned', label: 'Unassigned' }] : []),
			...(includeRoles ? [{ label: 'Roles', members: baseRoles }] : []),
			...rootStore.teams.list
				.map((team) => {
					const label = team.name
						? team.name
						: team.memberIds
						? rootStore.teams.getTeamName(team, true)
						: 'Unknown'

					return {
						label: label,
						members: (
							team.memberIds
								.map((memberId) => {
									const user = rootStore.organizationUsers.usersByUserId[memberId]
									if (!user || user.status !== 'active') {
										return undefined
									}

									return {
										value: user.id,
										label:
											user.firstName && user.lastName
												? `${user.firstName} ${user.lastName}`
												: user.email,
									}
								})
								.filter((v) => !!v) as { value: string; label: string }[]
						).sort((a, b) => a.label.localeCompare(b.label)),
					}
				})
				.sort((a, b) => a.label.localeCompare(b.label)),
		],
		100,
		[allMembers, unassigned, assigneeId]
	)

	const baseTeams = rootStore.teams.list.map((team) => {
		const label = team.name
			? team.name
			: team.memberIds
			? rootStore.teams.getTeamName(team, true)
			: ''

		return {
			value: team.id,
			label: label,
		}
	})

	const originalAssigneeOptions = useMemo(
		() => [
			// only show unassigned if there is no assignee
			...(allMembers ? [{ value: 'none', label: 'All Members' }] : []),
			...(!assigneeId || unassigned ? [{ value: 'unassigned', label: 'Unassigned' }] : []),
			...baseOptions,
		],
		[allMembers, assigneeId, unassigned, baseOptions]
	)
	const [search, setSearch] = useState(
		filterable
			? new Fuse<{ value: string; label: string }>(originalAssigneeOptions, {
					keys: ['label'],
			  })
			: null
	)

	const [assigneeOptions, setAssignOptions] = useState<
		(
			| { value: string; label: string }
			| {
					label: string
					members: { value: string; label: string }[]
			  }
		)[]
	>([...originalAssigneeOptions])

	const { query, wrapperRef, onFocus, onBlur } = useCustomFilterableInput()

	useEffect(() => {
		// Autofocus the input when the dropdown opens
		const timeoutId = setTimeout(() => {
			wrapperRef.current?.focus()
		}, 50)
		return () => {
			clearTimeout(timeoutId)
		}
	}, [open, wrapperRef])

	useEffect(() => {
		if (!value) {
			return
		}

		setAssigneeId(value)
	}, [value])

	useEffect(() => {
		if (!filterable) {
			setSearch(null)
		}
		setSearch(
			new Fuse<{ value: string; label: string }>(originalAssigneeOptions, {
				keys: ['label'],
			})
		)
	}, [filterable, originalAssigneeOptions])

	useEffect(() => {
		search?.setCollection(originalAssigneeOptions)
	}, [originalAssigneeOptions, search])

	const handleUpdateAssignee = async (userId: string) => {
		onChanged?.(userId)
		setAssigneeId(userId)
		setOpen(false)

		if (!task || task.isPrivate) {
			return
		}

		if (!userId) {
			return
		}

		await rootStore.tasks.getTaskById(task.id)?.assignTo(userId)
	}

	useEffect(() => {
		if (assigneeId) {
			setAssignee(rootStore.organizationUsers.users[assigneeId])
		}
	}, [assigneeId])

	useEffect(() => {
		if (!query) {
			if (groupByTeams) {
				setAssignOptions([...baseOptionsByGroup])
			} else {
				setAssignOptions([...originalAssigneeOptions])
			}
			return
		}

		const id = setTimeout(() => {
			if (!search) {
				return
			}

			const res = search.search(query)
			const items = res.map((r) => r.item)
			if (groupByTeams) {
				const itemsByGroup = rootStore.teams.list.map((team) => {
					const label = team.name
						? team.name
						: team.memberIds
						? rootStore.teams.getTeamName(team, true)
						: 'Unknown'

					return {
						id: team.id,
						label: label,
						members: [] as { value: string; label: string }[],
					}
				})

				const teamsByOrgUserId = rootStore.teams.list.reduce((acc, team) => {
					team.memberIds.forEach((memberId) => {
						const user = rootStore.organizationUsers.usersByUserId[memberId]
						if (!user) {
							return
						}
						if (!acc[user.id]) {
							acc[user.id] = []
						}
						acc[user.id].push(team)
					})
					return acc
				}, {} as Record<string, ITeam[]>)

				console.log(teamsByOrgUserId)

				items.forEach((item) => {
					const teams = teamsByOrgUserId[item.value] ?? []
					teams.forEach((team) => {
						const idx = itemsByGroup.findIndex((g) => g.id === team.id)
						if (idx > -1) {
							itemsByGroup[idx].members.push(item)
						}
					})
				})

				itemsByGroup.forEach((g) => {
					g.members.sort((a, b) => a.label.localeCompare(b.label))
				})

				setAssignOptions(itemsByGroup.filter((g) => g.members.length > 0))
			} else {
				setAssignOptions(items)
			}

			ref.current?.focus()
		}, 300)

		return () => {
			clearTimeout(id)
		}
	}, [search, query, originalAssigneeOptions, groupByTeams, baseOptionsByGroup])

	function iconForAssignee(assigneeId: string) {
		if (baseTeams.find((t) => t.value === assigneeId)) {
			return <HiUsers />
		}

		if (baseRoles.find((r) => r.value === assigneeId)) {
			return <PiUserCircleGear size={20} />
		}

		if (assigneeId === 'none') {
			return <AllMembersIcon />
		}

		if (assigneeId === 'unassigned') {
			return <UnassignedIcon />
		}

		const user = rootStore.organizationUsers.users[assigneeId]
		if (user) {
			return <OrgUserAvatar size="xs" user={user} />
		}

		return null
	}

	function labelForAssignee(assigneeId: string) {
		if (baseTeams.find((t) => t.value === assigneeId)) {
			return baseTeams.find((t) => t.value === assigneeId)?.label
		}

		if (baseRoles.find((t) => t.value === assigneeId)) {
			return baseRoles.find((t) => t.value === assigneeId)?.label
		}

		if (!assigneeId) {
			return '--'
		}

		switch (assigneeId) {
			case 'none':
				return 'All Members'
			case 'unassigned':
				return <span className="text-slate-500">Unassigned</span>
			default:
				return assignee?.firstName && assignee.lastName
					? `${assignee?.firstName ?? 'Unknown'} ${assignee?.lastName ?? ''}`
					: assignee?.email
		}
	}

	const isDisabled = task ? !!task.completedAt || task.isPrivate : false

	return (
		<div>
			<Dropdown
				side="bottom"
				align="start"
				open={open}
				onCloseAutoFocus={() => setOpen(false)}
				onEscapeKeyDown={() => setOpen(false)}
				onPointerDownOutside={() => setOpen(false)}
				onFocusOutside={() => setOpen(false)}
				onInteractOutside={() => setOpen(false)}
				trigger={
					<div
						className={clsx(
							'shadow-sm rounded px-2 border border-slate-100 h-[34px] min-w-fit flex flex-row items-center justify-between dark:bg-slate-700 dark:border-slate-700',
							{
								'cursor-pointer hover:bg-[#f8f9fa]': !isDisabled,
								'cursor-not-allowed': isDisabled,
								'pointer-events-none': isDisabled,
								'bg-gray-50': isDisabled,
							}
						)}
						onClick={() => setOpen(!open)}
					>
						{iconForAssignee(assigneeId)} &nbsp;&nbsp;
						<span className="whitespace-nowrap flex flex-grow">
							{labelForAssignee(assigneeId)}
						</span>
						{rightIcon}
					</div>
				}
			>
				<div className="w-[300px]">
					{filterable && (
						<div className="sticky top-0 bg-white border-gray-200 border-t border-x rounded-t-[10px]">
							<div className="flex p-2">
								<input
									ref={wrapperRef}
									type="text"
									className={clsx(
										'w-full text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block p-1 dark:placeholder-gray-400 dark:text-white border-0 ring-0'
									)}
									aria-label="filter members"
									placeholder={inputPlaceholder ?? 'Filter tasks for'}
									value={query}
									onFocus={onFocus}
									onBlur={onBlur}
									onChange={() => {
										//do nothing...
									}}
								/>
							</div>
							<hr className="h-px bg-gray-200 border-0 dark:bg-gray-700"></hr>
						</div>
					)}
					<div className="border-gray-200 border-b border-x rounded-b-[10px]">
						{groupByTeams ? (
							assigneeOptions.map((option, idx) => {
								if ('members' in option) {
									return (
										<>
											<p
												key={`${idx}`}
												className="text-sm text-slate-500 p-1"
											>
												{option.label}{' '}
											</p>
											{option.members.map((member, index) => {
												return (
													<CustomDropdownItem
														key={`${idx}-${index}`}
														onClick={() =>
															handleUpdateAssignee(member.value)
														}
													>
														{iconForAssignee(member.value)}
														<span className="truncate">
															{member.label}
														</span>
													</CustomDropdownItem>
												)
											})}
										</>
									)
								} else {
									return (
										<CustomDropdownItem
											key={`${idx}`}
											onClick={() => handleUpdateAssignee(option.value)}
										>
											{iconForAssignee(option.value)}
											<span className="truncate">{option.label}</span>
										</CustomDropdownItem>
									)
								}
							})
						) : (
							<>
								<p className="text-sm text-slate-500 p-1">Members</p>
								{(
									assigneeOptions as {
										value: string
										label: string
									}[]
								).map((ao, index) => {
									return (
										<CustomDropdownItem
											key={`${index}`}
											onClick={() => handleUpdateAssignee(ao.value)}
										>
											{iconForAssignee(ao.value)}
											<span className="truncate">{ao.label}</span>
										</CustomDropdownItem>
									)
								})}
							</>
						)}

						{includeTeams && (
							<>
								<p className="text-sm text-slate-500 p-1">Teams </p>
								{baseTeams.map((team, index) => {
									return (
										<CustomDropdownItem
											key={`team-${index}`}
											onClick={() => handleUpdateAssignee(team.value)}
										>
											<HiUsers className="min-w-[20px]" />
											&nbsp;
											<span className="truncate">{team.label}</span>
										</CustomDropdownItem>
									)
								})}
							</>
						)}
					</div>
				</div>
			</Dropdown>
		</div>
	)
})
