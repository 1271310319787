import { Filter } from 'mongodb'
import { authHeader, post } from './api'

import type {
	EmbeddingType,
	IAccount,
	ICandidate,
	ICard,
	IEmbedding,
	IPosition
} from '@touchpoints/requests'

export async function findSimilarPositionCandidates(
	orgId: string,
	positionCandidateId: string,
	excludeSameAccount?: boolean,
	excludeSamePosition?: boolean
) {
	const headers = await authHeader()
	let url = `/organizations/${orgId}/experimental/embeddings/position-candidates/${positionCandidateId}/find-similars`

	if (excludeSameAccount) {
		url += '?excludeSameAccount=true'
	}

	if (excludeSamePosition) {
		url += excludeSameAccount ? '&excludeSamePosition=true' : '?excludeSamePosition=true'
	}

	const response = await post<{ cards: ICard[] }>(url, undefined, headers)

	return response
}

export async function findSimilarPositions(orgId: string, positionId: string) {
	const headers = await authHeader()

	const response = await post<{ positions: IPosition[]; accountsById: Record<string, IAccount> }>(
		`/organizations/${orgId}/experimental/embeddings/positions/${positionId}/find-similars`,
		undefined,
		headers
	)

	return response
}

export async function findRecommendations(
	orgId: string,
	body: {
		query: {
			text?: string
			positionId?: string
			positionCandidateId?: string
		}
		filter?: Filter<IEmbedding>
	}
) {
	const headers = await authHeader()

	const response = await post<{
		results: {
			resourceId: string
			resourceType: EmbeddingType
			score: number
			payload: IPosition | ICandidate
		}[]
	}>(`/organizations/${orgId}/experimental/embeddings/query`, body, headers)

	return response
}
