import { rootStore } from '@store'
import { useReaction } from '@touchpoints/mobx-hooks'
import type {
	ICard,
	ICandidate,
	IOrganizationUser,
	IPosition,
	IPositionCandidate,
} from '@touchpoints/requests'

import clsx from 'clsx'

import { memo, useEffect, useRef } from 'react'

import { useIntersectionObserver } from 'usehooks-ts'

import { CandidateStageTimeline } from '@components/candidates/CandidateStageTimeline'
import { OrgUserAvatar } from '@components/shared/OrgUserAvatar'
import { formatTaskDate } from '@services/date'
import { Pill } from '@touchpoints/ui'
import type { BoardEntry } from '@types'
import { Tooltip } from 'flowbite-react'
import { FaRegComment } from 'react-icons/fa'
import { HiOutlineMail, HiOutlineUser } from 'react-icons/hi'
import { formatPhoneNumber, isValidPhoneNumber } from 'react-phone-number-input'
import { toast } from 'react-toastify'
import { BoardHeader } from './BoardHeader'
import { useDueTimeColor } from './hooks'
import { store } from './store'

export const positionCandidateToBoardEntry = (
	pc: IPositionCandidate
): {
	id: string
	positionCandidate: IPositionCandidate
	candidate?: ICandidate
	recruiter?: IOrganizationUser
	position?: IPosition
} => {
	const recruiter = pc?.recruiterId
		? rootStore.organizationUsers.users[pc.recruiterId]
		: undefined

	const position = pc?.positionId ? rootStore.positions.getPositionById(pc.positionId) : undefined

	const candidate = pc?.candidate
		? pc.candidate
		: rootStore.candidates.getCandidateById(pc.candidateId)

	return {
		id: pc.id,
		positionCandidate: pc,
		candidate: candidate,
		recruiter: recruiter,
		position: position,
	}
}

type BoardListProps = {
	positionId: string
	boardEntries: BoardEntry[]
	loadingCandidates: boolean
	useIntersectionRenderer?: boolean
	intersectionThreshold?: number
	onClick?: (boardCard: ICard) => void
}
export const BoardList = memo(function BoardList({
	positionId,
	boardEntries,
	loadingCandidates,
	useIntersectionRenderer = true,
	intersectionThreshold: threshold = 0,
	onClick,
}: BoardListProps) {
	const ref = useRef<HTMLDivElement>(null)

	const entry = useIntersectionObserver(ref, { threshold })
	const position = useReaction(() => rootStore.positions.getPositionById(positionId), 100, [
		positionId,
	])

	if (!position) {
		console.log('no position', boardEntries)
		return null
	}

	if (boardEntries.length <= 0) {
		console.log('no board entries', boardEntries)
		return <></>
	}

	const sortByStageName = (a: BoardEntry, b: BoardEntry) => {
		if (!a.card || !b.card) return 0
		const aStageId = a.card.stage
		const bStageId = b.card.stage
		const aStage = aStageId ? rootStore.stages.candidatesStageById[aStageId]?.name ?? '' : ''
		const bStage = bStageId ? rootStore.stages.candidatesStageById[bStageId]?.name ?? '' : ''
		return aStage.localeCompare(bStage)
	}

	boardEntries.sort(sortByStageName)

	const renderCards =
		!useIntersectionRenderer || (useIntersectionRenderer && !!entry?.isIntersecting)

	return (
		<div
			ref={ref}
			className={clsx('flex flex-col w-full rounded-md mb-3 overflow-y-clip p-2 space-y-3', {
				'animate-pulse': !!loadingCandidates,
			})}
		>
			<BoardHeader positionId={positionId} boardEntries={boardEntries} />
			{renderCards && (
				<div className="w-full flex flex-col rounded-md border shadow-sm p-2 divide-y">
					<BoardRowHeader />
					{boardEntries.map((entry) => {
						if (entry.loading) {
							return <LoadingCandidate key={entry.card?.id} />
						}
						if (!entry.card) {
							return
						}
						return (
							<>
								<BoardRow
									key={entry.card.id}
									boardCard={entry.card}
									onClick={() => {
										if (onClick && entry.card) {
											onClick(entry.card)
											return
										}

										store.setSelectedPositionCandidateId(entry.card?.id ?? '')
										store.setBoardEntries(boardEntries)
									}}
								/>
							</>
						)
					})}
				</div>
			)}
		</div>
	)
})

const BoardRowHeader = memo(function BoardRowHeader() {
	return (
		<div className="flex w-full p-2 space-x-2 items-center ">
			<div className="flex w-6 justify-center">
				<HiOutlineUser className="text-neutral-500" />
			</div>
			<div className="flex w-1/5">
				<p className="text-sm truncate text-neutral-600">Name</p>
			</div>
			<div className="flex justify-start w-2/5">
				<p className="text-sm truncate text-neutral-600">Stage</p>
			</div>

			<div className="flex items-center w-1/5">
				<p className="text-sm truncate text-neutral-600">Contact</p>
			</div>

			<div className="flex justify-between items-center">
				<p className="text-sm truncate text-neutral-600">Next Due Date</p>
			</div>
			<div className="flex justify-between items-center w-9"></div>
		</div>
	)
})

type BoardRowProps = {
	boardCard: ICard
	onClick?: () => void
}
const BoardRow = memo(function BoardRow({ boardCard, onClick }: BoardRowProps) {
	const ref = useRef<HTMLDivElement>(null)

	const dueByColor = useDueTimeColor(boardCard.nearestEventDate)
	const activePositionCandidateId = useReaction(() => store.activePositionCandidateId)
	const candidate = boardCard.candidate
	const recruiter = boardCard.recruiterId
		? rootStore.organizationUsers.users[boardCard.recruiterId]
		: undefined

	useEffect(() => {
		// auto scroll only if position candidate is selected
		if (activePositionCandidateId === boardCard.id) {
			const element = document.getElementById(`board-row-${store.activePositionCandidateId}`)
			element?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })
		}
	}, [activePositionCandidateId, boardCard.id])

	return (
		<div
			id={`board-row-${boardCard.id}`}
			ref={ref}
			className={clsx(
				'flex w-full p-2 space-x-2 cursor-pointer items-center hover:bg-neutral-100 rounded-md',
				{
					'bg-[#E1F0FF]': boardCard.id === store.activePositionCandidateId,
				}
			)}
			onClick={onClick}
		>
			<div className="flex w-6 overflow-x-clip truncate">
				{recruiter && <OrgUserAvatar size="xs" user={recruiter} />}
			</div>
			<div className="flex w-1/5 truncate">
				<p className="text-base">{`${candidate.firstName} ${candidate.lastName}`}</p>
			</div>
			<div className="flex justify-start w-2/5 overflow-x-clip truncate">
				<CandidateStageTimeline
					currentStageId={boardCard.stage}
					stageHistory={boardCard.stageHistory}
				/>
			</div>

			<div className="flex items-center w-1/5 overflow-x-clip truncate">
				<div className="flex space-x-2 items-center">
					{candidate?.email && (
						<Tooltip content={candidate?.email}>
							<HiOutlineMail
								className="text-gray-500 cursor-pointer"
								onClick={async (e) => {
									e.stopPropagation()
									navigator.clipboard.writeText(candidate.email)
									toast.success(`Copied to clipboard!`)
								}}
							/>
						</Tooltip>
					)}
					{candidate?.phone && isValidPhoneNumber(candidate?.phone) ? (
						<p className="text-sm text-gray-500 truncate mr-2">
							{formatPhoneNumber(candidate.phone)}
						</p>
					) : (
						<p className="text-sm text-gray-500 truncate mr-2">--</p>
					)}
				</div>
			</div>

			<div className="flex justify-between items-center w-36 overflow-x-clip truncate">
				<div className="flex space-x-2 items-center">
					{boardCard.nearestEventDate && (
						<Pill size="xs" className="py-1 px-2" color={dueByColor}>
							{formatTaskDate(boardCard.nearestEventDate)}
						</Pill>
					)}
				</div>
			</div>
			<div className="flex justify-between items-center w-9 overflow-x-clip truncate">
				<div className="flex items-center space-x-1 text-gray-500">
					<FaRegComment />
					{boardCard.commentsLength > 0 && <p>{boardCard.commentsLength}</p>}
				</div>
			</div>
		</div>
	)
})

const LoadingCandidate = memo(function LoadingCandidate() {
	return (
		<div className="p-3 rounded-md border shadow-sm max-w-sm w-full mx-auto">
			<div className="animate-pulse flex flex-col space-y-4">
				<div className="flex-1 space-y-6 py-1">
					<div className="h-2 bg-slate-300 rounded"></div>
					<div className="space-y-3">
						<div className="grid grid-cols-3 gap-4">
							<div className="h-2 bg-slate-300 rounded col-span-2"></div>
							<div className="h-2 bg-slate-300 rounded col-span-1"></div>
						</div>
						<div className="h-2 bg-slate-300 rounded"></div>
					</div>
				</div>
				<div className="flex justify-between">
					<div className="h-2 bg-slate-300 rounded col-span-2"></div>
					<div className="rounded-full bg-slate-300 h-5 w-5"></div>
				</div>
			</div>
		</div>
	)
})
