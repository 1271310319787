import clsx from 'clsx'
import format from 'date-fns/format'
import { observer } from 'mobx-react-lite'
import {
	HiCalendar,
	HiFlag,
	HiOutlineCalendar,
	HiPaperAirplane,
	HiTrash,
	HiUserCircle,
} from 'react-icons/hi'

import { orgUserNameDisplay } from '@services/format'
import { rootStore } from '@store/index'
import { QuestionIcon } from '@touchpoints/icons'
import {
	ICandidateEvent,
	IPositionCandidateChecklist,
	IPositionCandidateEvent,
	IPositionCandidateQuestionForm,
} from '@touchpoints/requests'
import type * as Types from '@touchpoints/service-types'
import { ActivityItem, ActivityWrapper, IconHeader, Tabs } from '@touchpoints/ui'
import { PiPulse } from 'react-icons/pi'
import { formatPhoneNumber } from 'react-phone-number-input'
import { CandidateStageColorDot } from './CandidateStageColorDot'
import { useCandidateEvents } from './useCandidateEvents'
import { getOrgUserIdForEvent } from '@touchpoints/utils'
import { MdAttachment } from 'react-icons/md'

type CandidateActivityTabProps = {
	tabValue: string
	candidateId: string
}
export const CandidateActivityTab = observer(function ({
	tabValue,
	candidateId,
}: CandidateActivityTabProps) {
	const canDeleteRow = rootStore.organizationUsers.activeUser?.role === 'admin'
	const events = useCandidateEvents(candidateId)

	const handleRowDeleted = async (event: ICandidateEvent | IPositionCandidateEvent) => {
		if (!event.id) {
			return
		}

		if (event.resourceType === 'candidate-position') {
			await rootStore.candidates.events.deletePositionCandidateEvent(
				event.positionId,
				event.candidateId,
				event.id
			)
		}

		if (event.resourceType === 'candidate') {
			await rootStore.candidates.events.deleteCandidateEvent(event.resourceId, event.id)
		}
	}

	const act: ActivityItem[] = events.map((e) => {
		const message = getMessageForEvent(e)
		const icon = getIconForEvent(e)

		return {
			icon,
			message: (
				<div>
					<span className={clsx('text-sm text-gray-500')}>
						<span
							className={clsx({
								'line-through': e.deleted,
							})}
						>
							{message}
						</span>
						{e.deleted && (
							<span className="no-underline text-sm text-gray-400 ">
								{` (event deleted by ${orgUserNameDisplay(
									rootStore.organizationUsers.users[e.deletedBy as string]
								)})`}{' '}
							</span>
						)}
					</span>
				</div>
			),
			ts: e.ts,
			after: (
				<>
					{canDeleteRow && !e.deleted && (
						<div className="hover:cursor-pointer text-sm text-gray-500 hover:text-red-500">
							<HiTrash onClick={() => handleRowDeleted(e)} />
						</div>
					)}
				</>
			),
		}
	})

	return (
		<Tabs.Content value={tabValue} className="h-96 overflow-y-auto !bg-transparent">
			<div className="flex flex-col space-y-3">
				<IconHeader
					icon={<PiPulse className="text-[#8E4EC6]" />}
					message="Activity"
				></IconHeader>

				<ActivityWrapper items={act} />
			</div>
		</Tabs.Content>
	)
})

export function getOrgUserForEvent(event: ICandidateEvent | IPositionCandidateEvent) {
	const _id = getOrgUserIdForEvent(event)
	if (!_id) {
		return undefined
	}

	const orgUser = rootStore.organizationUsers.users[_id]

	return orgUser
}

export function getMessageForEvent(
	event: ICandidateEvent | IPositionCandidateEvent,
	opts: { includeBy?: boolean } = {}
) {
	const metadata = event.payload?.metadata ?? event.payload
	const { source, name } = (metadata ?? {}) as {
		source?: string
		name?: string
		title?: string
	}

	const orgUser = getOrgUserForEvent(event)
	const { includeBy = true } = opts

	const sourceExtra =
		source === 'user'
			? orgUser
				? orgUserNameDisplay(orgUser)
				: 'Unknown'
			: source === 'trigger' && name
			? `${name}`
			: ''

	const black = (str?: string) => {
		return <span className="text-black">{str ?? 'unknown'}</span>
	}

	switch (event.name) {
		case 'stage-changed': {
			const stageId = (event.payload?.stage ?? '') as string
			const positionId = event.positionId
			const position = rootStore.positions.getPositionById(positionId)

			return (
				<span className="text-slate-500 text-base">
					{black('Stage changed')} to{' '}
					{black(rootStore.stages.candidatesStageById[stageId]?.name)}
					{position && (
						<>
							{' '}
							for {black(position.name)} at {black(position.account?.name)}
						</>
					)}
					{includeBy && sourceExtra && <> by {black(sourceExtra)}</>}
				</span>
			)
		}
		case 'recent-employer-changed':
			return (
				<span className="text-slate-500 text-base">
					{black('Recent employer')} changed to{' '}
					{black(event.payload?.recentEmployer as string)}
					{event.payload?.original
						? ` from ${black(event.payload?.original as string)}`
						: ''}
				</span>
			)

		case 'first-name-changed':
			return (
				<span className="text-slate-500 text-base">
					{black('First name')} changed to {black(event.payload?.firstName as string)}
				</span>
			)

		case 'last-name-changed':
			return (
				<span className="text-slate-500 text-base">
					{black('Last name')} changed to {black(event.payload?.lastName as string)}
				</span>
			)

		case 'email-changed':
			return (
				<span className="text-slate-500 text-base">
					{black('Email')} changed to {black(event.payload?.email as string)}
				</span>
			)

		case 'phone-changed':
			return (
				<span className="text-slate-500 text-base">
					{black('Phone')} changed to{' '}
					{black(formatPhoneNumber(event.payload?.phone as string) ?? '')}
				</span>
			)

		case 'recent-job-title-changed':
			return (
				<span className="text-slate-500 text-base">
					{black('Recent job title')} changed to{' '}
					{black(event.payload?.recentJobTitle as string)}
				</span>
			)

		case 'main-contact-id-changed':
			return (
				<span className="text-slate-500 text-base">
					{black('Main contact')} changed to{' '}
					{black(
						rootStore.organizationUsers.usersForActiveOrg.find(
							(user) => user.id === (event.payload?.mainContactId as string)
						)?.email
					)}
				</span>
			)

		case 'sourcer-changed': {
			return (
				<span className="text-slate-500 text-base">
					{black('Sourcer')} changed to{' '}
					{black(
						rootStore.organizationUsers.usersForActiveOrg.find(
							(user) => user.id === (event.payload?.sourcer as string)
						)?.email
					)}
				</span>
			)
		}

		case 'custom-field-changed': {
			const customField = event.payload?.customField as Record<string, unknown>
			const key = Object.keys(customField)[0]
			const cf = customField[key] as { value: unknown }
			const value = cf?.value ?? 'unknown'
			const displayValue = Array.isArray(value) ? value.join(', ') : value
			const field = rootStore.candidates.settings.customFields.find(
				(field) => field.key === key
			)
			return (
				<span className="text-slate-500 text-base">
					{black('Custom field')} {black(field?.name)} changed to{' '}
					{black(displayValue as string)}
				</span>
			)
		}

		case 'scheduled-event-changed': {
			const { name, time, action, orgUserId } = event.payload as {
				name: string
				time?: number
				action: 'update-time' | 'remove' | 'completed'
				orgUserId?: string
			}
			let message: any
			const orgUser = orgUserId ? rootStore.organizationUsers.users[orgUserId] : undefined

			switch (action) {
				case 'update-time':
					message = (
						<span>
							time changed to{' '}
							{black(format(new Date(time as number), 'MMM do hh:mm a'))}
						</span>
					)
					break
				case 'remove':
					message = `was removed`
					break
				case 'completed':
					message = `was completed`
					break
			}
			return (
				<span className="text-slate-500 text-base">
					Scheduled event {black(name)} {message}
					{includeBy && orgUser && <span> by {black(orgUserNameDisplay(orgUser))}</span>}
				</span>
			)
		}

		case 'question-created': {
			const { title } = event.payload as { title: string }
			return (
				<span className="text-slate-500 text-base">Created question: {black(title)}</span>
			)
		}

		case 'question-completed': {
			const { title } = event.payload as { title: string }
			return (
				<span className="text-slate-500 text-base">
					Completed question: {black(title)}
					{includeBy && sourceExtra && <> by {black(sourceExtra)}</>}
				</span>
			)
		}

		case 'question-item-completed': {
			const { title, selection } = event.payload as { title: string; selection?: string }
			return (
				<span className="text-slate-500 text-base">
					Completed question item: {black(title)}{' '}
					{selection && <span>({selection})</span>}
					{includeBy && sourceExtra && <> by {black(sourceExtra)}</>}
				</span>
			)
		}

		case 'checklist-item-checked': {
			const { title } = event.payload as { title: string }
			return (
				<span className="text-slate-500 text-base">
					Completed checklist item: {black(title)}
					{includeBy && sourceExtra && <> by {black(sourceExtra)}</>}
				</span>
			)
		}

		case 'checklist-item-unchecked': {
			const { title } = event.payload as { title: string }
			return (
				<span className="text-slate-500 text-base">
					Unchecked checklist item: {black(title)}
					{includeBy && sourceExtra && <> by {black(sourceExtra)}</>}
				</span>
			)
		}

		case 'checklist-completed': {
			const { title } = event.payload as { title: string }
			return (
				<span className="text-slate-500 text-base">
					Completed checklist: {black(title)}
					{includeBy && sourceExtra && <> by {black(sourceExtra)}</>}
				</span>
			)
		}

		case 'checklist-created': {
			const { title } = event.payload as { title: string }
			return (
				<span className="text-slate-500 text-base">Created checklist: {black(title)}</span>
			)
		}

		case 'action-items-cleared': {
			const items = event.payload?.items as (
				| IPositionCandidateChecklist
				| IPositionCandidateQuestionForm
			)[]
			return (
				<span className="text-slate-500 text-base">
					{black(
						`${items.length} uncompleted action item${items.length === 1 ? '' : 's'}`
					)}{' '}
					cleared ({items.map((item) => item.title).join(', ')})
				</span>
			)
		}

		case 'added-to-sequence': {
			const { sequenceId } = event.payload as { sequenceId: string }
			const sequence = rootStore.sequences.getSequenceById(sequenceId)
			return (
				<span className="text-slate-500 text-base">
					Added to sequence: {black(sequence?.name)}
				</span>
			)
		}

		case 'removed-from-sequence': {
			const { sequenceId, type } = event.payload as {
				sequenceId: string
				type: Types.PositionCandidateEventSequenceRemovedType
			}
			const sequence = rootStore.sequences.getSequenceById(sequenceId)
			switch (type) {
				case 'replied':
				case 'meeting-booked':
					return (
						<span className="text-slate-500 text-base">
							Completed sequence: {black(sequence?.name)} ({type})
						</span>
					)

				default:
					return (
						<span className="text-slate-500 text-base">
							Removed from sequence: {black(sequence?.name)} ({type})
						</span>
					)
			}
		}

		case 'add-to-sequence-failed': {
			const { sequenceId, message } = event.payload as {
				sequenceId: string
				message?: string
			}
			const sequence = rootStore.sequences.getSequenceById(sequenceId)
			return (
				<span className="text-slate-500 text-base">
					Failed to add to sequence: {black(sequence?.name)}{' '}
					{message ? `(${message})` : ''}
				</span>
			)
		}

		case 'meeting-booked': {
			const { meetingName, meetingTime, bookedWithUserId } = event.payload as {
				meetingName: string
				meetingTime: number
				bookedWithUserId?: string
			}
			const recruiter = bookedWithUserId
				? rootStore.organizationUsers.users[bookedWithUserId]
				: undefined

			return (
				<span className="text-slate-500 text-base">
					{black(meetingName)} booked at{' '}
					{black(format(new Date(meetingTime), 'MMM do hh:mm a'))}
					{recruiter && <span>with {black(orgUserNameDisplay(recruiter))}</span>}
				</span>
			)
		}

		case 'recruiter-changed': {
			const { recruiterId } = event.payload as { recruiterId?: string }
			const recruiter = recruiterId
				? rootStore.organizationUsers.users[recruiterId]
				: undefined
			return (
				<span className="text-slate-500 text-base">
					{black('Recruiter')} changed to {black(orgUserNameDisplay(recruiter))}
				</span>
			)
		}

		case 'attachment-created': {
			const { name, createdBy } = event.payload as { name: string; createdBy: string }
			const user = createdBy
				? rootStore.organizationUsers.usersByUserId[createdBy]
				: undefined

			const { includeBy = true } = opts
			return (
				<span className="text-slate-500 text-base">
					Attachment {black(name)} was created{' '}
					{includeBy && user && <> by {black(orgUserNameDisplay(user))}</>}
				</span>
			)
		}

		case 'attachment-deleted': {
			const { name, deletedBy } = event.payload as { name: string; deletedBy: string }
			const user = deletedBy
				? rootStore.organizationUsers.usersByUserId[deletedBy]
				: undefined

			const { includeBy = true } = opts
			return (
				<span className="text-slate-500 text-base">
					Attachment {black(name)} was deleted{' '}
					{includeBy && user && <> by {black(orgUserNameDisplay(user))}</>}
				</span>
			)
		}

		default:
			return <span className="text-slate-500 text-base">{event.name}</span>
	}
}

export function getIconForEvent(event: ICandidateEvent | IPositionCandidateEvent) {
	switch (event.name) {
		case 'stage-changed': {
			const stageId = (event.payload?.stage ?? '') as string
			const stage = stageId
				? { ...rootStore.stages.candidatesStageById[stageId] }
				: { color: '#000000', name: 'Unknown' }

			return <CandidateStageColorDot className="m-1" size="sm" color={stage.color} noMargin />
		}
		case 'recent-employer-changed':
		case 'first-name-changed':
		case 'last-name-changed':
		case 'email-changed':
		case 'phone-changed':
		case 'timezone-changed':
		case 'skills-changed':
		case 'sourcer-changed':
		case 'nickname-changed':
		case 'recent-job-title-changed':
		case 'main-contact-id-changed':
		case 'custom-field-changed':
		case 'linkedin-url-changed':
		case 'friendly-slug-changed':
		case 'notes-changed':
		case 'resubmittable-changed':
			return (
				<div className="w-5 h-5 p-0.5 bg-[#F0F4FF] rounded-md">
					<HiUserCircle className="text-[#889096]" />
				</div>
			)
		case 'scheduled-event-changed':
			return (
				<div className="w-5 h-5 p-0.5 bg-[#F0F4FF] rounded-md">
					<HiOutlineCalendar className="text-[#8E4EC6]" />
				</div>
			)
		case 'checklist-item-checked':
		case 'checklist-item-unchecked':
		case 'question-completed':
		case 'checklist-completed':
			return (
				<div className="w-5 h-5 p-0.5 bg-[#F0F4FF] rounded-md">
					<QuestionIcon />
				</div>
			)
		case 'meeting-booked':
			return <HiCalendar />
		case 'added-to-sequence':
		case 'removed-from-sequence':
			return (
				<div className="w-5 h-5 p-0.5 bg-[#F0F4FF] rounded-md">
					<HiPaperAirplane className="text-[#889096]" />
				</div>
			)
		case 'attachment-created':
		case 'attachment-deleted':
			return (
				<div className="w-5 h-5 p-0.5 bg-[#F0F4FF] rounded-md">
					<MdAttachment />
				</div>
			)
		default:
			return <HiFlag />
	}
}
