import { rootStore } from '@store'
import { useReaction } from '@touchpoints/mobx-hooks'
import type { ICard } from '@touchpoints/requests'

import clsx from 'clsx'

import { memo, useRef } from 'react'

import { useIntersectionObserver } from 'usehooks-ts'
import { Card } from './Card'

import type { BoardEntry } from '@types'
import { BoardHeader } from './BoardHeader'
import { store } from './store'

type BoardColumnsProps = {
	positionId: string
	cards: BoardEntry[]
	loadingCandidates: boolean
	showAsCard?: boolean
	useIntersectionRenderer?: boolean
	intersectionThreshold?: number
	onClick?: (boardCard: ICard) => void
}
export const BoardColumn = memo(function BoardColumn({
	positionId,
	cards,
	loadingCandidates,
	showAsCard = true,
	useIntersectionRenderer = true,
	intersectionThreshold: threshold = 0,
	onClick,
}: BoardColumnsProps) {
	const ref = useRef<HTMLDivElement>(null)

	const entry = useIntersectionObserver(ref, { threshold })
	const position = useReaction(() => rootStore.positions.getPositionById(positionId), 100, [
		positionId,
	])

	if (!position) {
		console.log('no position', cards)
		return null
	}

	if (cards.length <= 0 && !loadingCandidates) {
		console.log('no board entries', cards)
		return <></>
	}

	const sortByStageName = (a: BoardEntry, b: BoardEntry) => {
		if (!a.card || !b.card) return 0
		const aStageId = a.card.stage
		const bStageId = b.card.stage
		const aStage = aStageId ? rootStore.stages.candidatesStageById[aStageId]?.name ?? '' : ''
		const bStage = bStageId ? rootStore.stages.candidatesStageById[bStageId]?.name ?? '' : ''
		return aStage.localeCompare(bStage)
	}

	cards.sort(sortByStageName)

	const renderCards =
		!useIntersectionRenderer || (useIntersectionRenderer && !!entry?.isIntersecting)

	return (
		<div
			ref={ref}
			className={clsx('flex flex-col w-[300px] h-full mb-3 overflow-y-clip overflow-x-clip', {
				'p-2 space-y-3 hover:bg-slate-50/70': showAsCard,
				'w-[250px] border-r': !showAsCard,
				'animate-pulse': !!loadingCandidates,
			})}
		>
			<BoardHeader positionId={positionId} boardEntries={cards} />
			<div className="flex flex-grow overflow-y-auto overflow-x-hidden">
				{renderCards && (
					<div
						className={clsx('h-96 w-full flex flex-col', {
							'space-y-3': showAsCard,
						})}
					>
						{cards.map((entry) => {
							if (entry.loading) {
								return <LoadingCandidate key={entry.card?.id} />
							}

							if (!entry.card) {
								return
							}
							return (
								<Card
									key={entry.card.id}
									card={entry.card}
									showAsCard={showAsCard}
									onClick={() => {
										if (onClick && entry.card) {
											onClick(entry.card)
											return
										}
										store.setSelectedPositionCandidateId(entry.card?.id ?? '')
										store.setBoardEntries(cards)
									}}
								/>
							)
						})}
					</div>
				)}
			</div>
		</div>
	)
})

const LoadingCandidate = memo(function LoadingCandidate() {
	return (
		<div className="p-3 rounded-md border shadow-sm max-w-sm w-full mx-auto">
			<div className="animate-pulse flex flex-col space-y-4">
				<div className="flex-1 space-y-6 py-1">
					<div className="h-2 bg-slate-300 rounded"></div>
					<div className="space-y-3">
						<div className="grid grid-cols-3 gap-4">
							<div className="h-2 bg-slate-300 rounded col-span-2"></div>
							<div className="h-2 bg-slate-300 rounded col-span-1"></div>
						</div>
						<div className="h-2 bg-slate-300 rounded"></div>
					</div>
				</div>
				<div className="flex justify-between">
					<div className="h-2 bg-slate-300 rounded col-span-2"></div>
					<div className="rounded-full bg-slate-300 h-5 w-5"></div>
				</div>
			</div>
		</div>
	)
})
