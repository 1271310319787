import type { FunnelStep, IFunnel } from '@touchpoints/requests'
import type { RootStore } from './root'
import { makeAutoObservable } from 'mobx'
import {
	addFunnel,
	deleteFunnel,
	getAllFunnels,
	getFunnelById,
	getFunnelData,
	updateFunnel,
} from '@requests/funnels'
import { cloneDeep } from 'lodash'
import { nanoid } from 'nanoid'

class Funnel implements IFunnel {
	id: string
	organizationId: string
	name: string
	steps: FunnelStep[]
	startDate: number
	endDate: number

	requirePreviousStage = true
	groupByRecruiter = false

	constructor(data: IFunnel) {
		this.id = data.id
		this.organizationId = data.organizationId
		this.name = data.name
		this.steps = data.steps ?? []
		this.startDate = data.startDate ?? 0
		this.endDate = data.endDate ?? 0
		this.requirePreviousStage = data.requirePreviousStage ?? true
		this.groupByRecruiter = data.groupByRecruiter ?? false
	}
}

export class FunnelsStore {
	private readonly root: RootStore

	list: Funnel[] = []

	constructor(root: RootStore) {
		this.root = root

		makeAutoObservable(this)
	}

	getFunnelById(id: string) {
		return this.list.find((f) => f.id === id)
	}

	async fetchAllFunnels() {
		const orgId = this.root.organizations.activeOrganizationId
		if (!orgId) {
			return
		}

		const res = await getAllFunnels(orgId)
		if (!res.success) {
			return
		}

		const { funnels } = res.data ?? {}
		if (!funnels) {
			return
		}

		this.list = funnels.map((funnel) => new Funnel(funnel))
	}

	async fetchFunnelById(id: string, skipIfExists = true) {
		const existing = this.list.find((f) => f.id === id)
		if (skipIfExists && existing) {
			return existing
		}

		const orgId = this.root.organizations.activeOrganizationId
		if (!orgId) {
			return
		}

		const res = await getFunnelById(orgId, id)
		if (!res.success) {
			return
		}

		const { funnel } = res.data ?? {}
		if (!funnel) {
			return
		}

		const newFunnel = new Funnel(funnel)

		const idx = this.list.findIndex((f) => f.id === id)

		if (idx < 0) {
			this.list.push(newFunnel)
		} else {
			this.list[idx] = newFunnel
		}

		return newFunnel
	}

	async updateFunnel(id: string, data: Partial<Omit<IFunnel, 'id'>>) {
		const orgId = this.root.organizations.activeOrganizationId
		if (!orgId) {
			return
		}

		const res = await updateFunnel(orgId, id, data)
		if (!res.success) {
			return
		}

		const { funnel } = res.data ?? {}
		if (!funnel) {
			return
		}

		const index = this.list.findIndex((f) => f.id === id)
		if (index === -1) {
			return
		}

		this.list[index] = new Funnel(funnel)

		return this.list[index]
	}

	async fetchFunnelData(id: string, accountId: string | undefined, positionIds: string[]) {
		const orgId = this.root.organizations.activeOrganizationId
		if (!orgId) {
			return
		}

		if (!positionIds && !accountId) {
			return
		}

		const res = await getFunnelData(orgId, id, accountId, positionIds)
		if (!res.success) {
			return
		}

		return res.data
	}

	async addFunnel(data: Partial<Omit<IFunnel, 'id' | 'organizationId'>>) {
		const orgId = this.root.organizations.activeOrganizationId
		if (!orgId) {
			return
		}

		const res = await addFunnel(orgId, data)
		if (!res.success) {
			return
		}

		const { funnel } = res.data ?? {}
		if (!funnel) {
			return
		}

		const f = new Funnel(funnel)
		this.list.push(f)

		return f
	}

	async deleteFunnel(id: string) {
		const orgId = this.root.organizations.activeOrganizationId
		if (!orgId) {
			return
		}

		const res = await deleteFunnel(orgId, id)
		if (!res.success) {
			return
		}

		this.list = this.list.filter((f) => f.id !== id)
	}

	async duplicate(id: string) {
		const orgId = this.root.organizations.activeOrganizationId
		if (!orgId) {
			return
		}

		const copy = cloneFunnel(this.getFunnelById(id))
		if (!copy) {
			return
		}

		const res = await addFunnel(orgId, copy)

		const { funnel } = res.data ?? {}
		if (!funnel) {
			return
		}

		const f = new Funnel(funnel)
		this.list.push(f)

		return f
	}
}

export function cloneFunnel(funnel?: IFunnel): IFunnel | undefined {
	if (!funnel) {
		return
	}

	const funnelId = nanoid()
	return {
		...funnel,
		id: funnelId,
		name: `${funnel.name} (copy)`,
		steps: funnel.steps.map((step: FunnelStep) => cloneStep(step)),
	}
}

export function cloneStep(step: FunnelStep): FunnelStep {
	const stepId = nanoid()
	return {
		...step,
		id: stepId,
	}
}
