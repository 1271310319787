import { makeAutoObservable, reaction } from 'mobx'

import type { RootStore } from './root'
import { fetchAirtableSettings, updateAirtableSettings } from '@requests/settings'
import type { IIntegrationSettings } from '@touchpoints/requests'

export class Settings implements IIntegrationSettings {
	private readonly root: RootStore

	useAirtableBase = false
	// the full key will not be stored here for security reasons
	airtableApiKey = ''
	airtableBaseId = ''
	airtableExcludeStages: string[] = []

	private saveTimeoutId?: number
	private savePromises: ((val: boolean) => void)[] = []

	constructor(root: RootStore) {
		this.root = root

		makeAutoObservable(this)

		reaction(
			() => root.organizations.activeOrganizationId,
			() => this.refresh()
		)
	}

	async refresh() {
		const orgId = this.root.organizations.activeOrganizationId
		if (!orgId) {
			return
		}

		const airtableRes = await fetchAirtableSettings(orgId)

		if (airtableRes.success) {
			const { settings = {} } = airtableRes.data ?? {}

			this.update(settings)
		}
	}

	async saveAirtable(newData: Omit<IIntegrationSettings, 'trelloToken'>) {
		const orgId = this.root.organizations.activeOrganizationId
		if (!orgId) {
			return
		}

		// optimistically update locally
		this.update(newData)

		if (this.saveTimeoutId) {
			window.clearTimeout(this.saveTimeoutId)
		}

		return new Promise((resolve) => {
			this.savePromises.push(resolve)

			this.saveTimeoutId = window.setTimeout(async () => {
				const res = await updateAirtableSettings(orgId, {
					useAirtableBase: this.useAirtableBase,
					airtableApiKey: this.airtableApiKey,
					airtableBaseId: this.airtableBaseId,
					airtableExcludeStages: this.airtableExcludeStages,
					...newData,
				})

				if (!res.success) {
					this.savePromises.forEach((resolve) => resolve(false))
					this.savePromises.length = 0
					return
				}

				const { settings = {} } = res.data ?? {}
				this.update(settings)
				this.savePromises.forEach((resolve) => resolve(true))
				this.savePromises.length = 0
				return
			}, 300)
		})
	}

	private update(data: IIntegrationSettings) {
		this.useAirtableBase = data.useAirtableBase ?? this.useAirtableBase
		this.airtableApiKey = data.airtableApiKey ?? this.airtableApiKey
		this.airtableBaseId = data.airtableBaseId ?? this.airtableBaseId
		this.airtableExcludeStages = data.airtableExcludeStages ?? this.airtableExcludeStages
	}
}
