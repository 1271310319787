import {
	addResumeToCandidate,
	deleteCandidateAttachment,
	fetchAllResumes,
	getAllResumesForCandidate,
} from '@requests/attachments'
import { AttachmentType, ICandidateAttachment } from '@touchpoints/requests'
import { makeAutoObservable } from 'mobx'
import type { RootStore } from '../root'

class CandidateAttachment implements ICandidateAttachment {
	id = ''
	resourceId = ''
	resourceType: 'candidate'
	orgId = ''
	filename = ''
	creationDate: Date
	type: AttachmentType = 'other'

	constructor(data: ICandidateAttachment) {
		this.id = data.id
		this.resourceId = data.resourceId
		this.resourceType = data.resourceType
		this.orgId = data.orgId
		this.filename = data.filename
		this.creationDate = data.creationDate
		this.type = data.type ?? 'other'

		makeAutoObservable(this)
	}
}

export class CandidateResumeStore {
	private readonly root: RootStore

	readonly list: ICandidateAttachment[] = []

	private _activeResumeId: string | null = null

	constructor(root: RootStore) {
		this.root = root

		makeAutoObservable(this)
	}

	get activeResumeId() {
		return this._activeResumeId
	}

	get activeResume() {
		if (!this._activeResumeId) {
			return null
		}

		return this.list.find((b) => b.id === this._activeResumeId) ?? null
	}

	setActiveAttachmentId(id: string | null) {
		this._activeResumeId = id
	}

	async fetchAllResumes() {
		const orgId = this.root.organizations.activeOrganizationId

		if (!orgId) {
			return
		}

		const res = await fetchAllResumes(orgId)

		if (!res) {
			return
		}

		const { attachments } = res.data ?? {}

		if (!attachments) {
			return
		}

		for (const attachment of attachments) {
			this.addAttachment(attachment)
		}
	}

	async addResumeToCandidate(candidateId: string, data: FormData, _orgId?: string) {
		const orgId = _orgId || this.root.organizations.activeOrganizationId

		if (!orgId) {
			return
		}

		const res = await addResumeToCandidate(orgId, candidateId, data)

		if (!res.success) {
			console.error(res.message)
			return
		}

		return res.data
	}

	async getAllResumesForCandidate(candidateId: string, _orgId?: string) {
		const orgId = _orgId || this.root.organizations.activeOrganizationId

		if (!orgId) {
			return
		}

		const res = await getAllResumesForCandidate(orgId, candidateId)
		if (!res) {
			return
		}

		const { attachments } = res.data ?? {}

		if (!attachments) {
			return
		}

		for (const attachment of attachments) {
			this.addAttachment(attachment)
		}

		return attachments
	}

	async deleteResumeFromCandidate(candidateId: string, resumeId: string) {
		const orgId = this.root.organizations.activeOrganizationId

		if (!orgId) {
			return
		}

		const res = await deleteCandidateAttachment(orgId, candidateId, resumeId)

		if (!res.success) {
			console.error(res.message)
			return res
		}

		return res.success
	}

	getByCandidateId(candidateId: string): ICandidateAttachment[] {
		return this.list.filter((t) => t.resourceId === candidateId)
	}

	getById(id: string) {
		return this.list.find((t) => t.id === id)
	}

	private addAttachment(attachment: ICandidateAttachment) {
		const existing = this.getById(attachment.id)

		if (existing) {
			Object.assign(existing, attachment)
		} else {
			this.list.push(new CandidateAttachment(attachment))
		}
	}
}
