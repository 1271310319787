import {
	fetchCandidate,
	fetchCandidates,
	removeCandidate,
	searchCandidates,
	updateCandidate,
} from '@requests/candidates'
import { makeAutoObservable, observable, runInAction } from 'mobx'

import type {
	CandidateChanges,
	CandidateTimeSelectionStatus,
	CustomField,
	ICandidate,
	Query,
} from '@touchpoints/requests'

import { createAsyncQueue } from '@services/queue'
import { groupBy } from 'lodash'
import type { RootStore } from '../root'
import { CandidateEvents } from './candidateEvents'
import { CandidateEmailThreads } from './emailThreads'
import { CandidateResumeStore } from './resume'
import { CandidatesSettings } from './settings'
import { CandidateStageCounts } from './stageCounts'
import { CandidatesViews } from './views'

class Candidate implements ICandidate {
	id = ''
	organizationId = ''
	firstName = ''
	lastName = ''
	nickname = ''
	email = ''
	skills?: string[] = []
	phone = ''
	additionalPhones: string[] = []
	recentEmployer = ''
	recentJobTitle = ''
	sequences: string[] = []
	completedSequences: string[] = []
	additionalEmails?: string[]
	mainContactId = ''
	sourcer = ''
	linkedinUrl = ''
	linkedinRecruiterUrl = ''
	location = ''
	timezone = ''
	customFields: Record<string, CustomField> = {}
	notes? = ''
	resubmittable? = false

	createdBy = ''
	createdAt = 0

	// NOTE: this is only added when data came from a search
	// from the API
	_accountId?: string
	_emailSources = {}

	constructor(data: ICandidate & { _accountId?: string }) {
		this.update(data)
	}

	update(data: ICandidate & { _accountId?: string }) {
		this.id = data.id
		this.organizationId = data.organizationId
		this.firstName = data.firstName
		this.lastName = data.lastName
		this.nickname = data.nickname ?? ''
		this.email = data.email
		this.skills = data.skills ?? []
		this.phone = data.phone ?? ''
		this.additionalPhones = data.additionalPhones ?? []
		this.recentEmployer = data.recentEmployer ?? ''
		this.recentJobTitle = data.recentJobTitle ?? ''
		this.sequences = [...(data.sequences ?? [])]
		this.completedSequences = [...(data.completedSequences ?? [])]
		this.additionalEmails = [...(data.additionalEmails ?? [])]
		this.mainContactId = data.mainContactId ?? ''
		this.sourcer = data.sourcer ?? ''
		this.linkedinUrl = data.linkedinUrl ?? ''
		this.linkedinRecruiterUrl = data.linkedinRecruiterUrl ?? ''
		this.location = data.location ?? ''
		this.timezone = data.timezone ?? ''
		this.customFields = data.customFields ?? {}
		this.notes = data.notes ?? ''
		this.resubmittable = data.resubmittable ?? false
		this.createdBy = data.createdBy ?? ''
		this.createdAt = data.createdAt ?? 0
		this._emailSources = data._emailSources ?? {}

		this._accountId = data._accountId ?? this._accountId
	}

	wasAddedToSequenceOnce(sequenceId: string) {
		console.log(this.id, this.sequences, this.completedSequences)
		return this.sequences.includes(sequenceId) || this.completedSequences.includes(sequenceId)
	}
}

export interface CandidatesPageProps {
	page: number
	rows: number
	filter: {
		query?: Query
		searchTerm?: string
	}
}

export class CandidatesStore {
	readonly rootStore: RootStore
	readonly settings: CandidatesSettings
	readonly views: CandidatesViews
	readonly stageCounts: CandidateStageCounts
	readonly history: CandidateEmailThreads
	readonly resumes: CandidateResumeStore
	readonly events: CandidateEvents

	readonly list: Candidate[] = []

	private candidatesById: Record<string, Candidate> = {}

	private readonly requestsQueue = createAsyncQueue({ maxChannels: 3, sleepBetween: 50 })

	constructor(root: RootStore) {
		this.rootStore = root
		this.settings = new CandidatesSettings(root)
		this.views = new CandidatesViews(root)
		this.stageCounts = new CandidateStageCounts(root)
		this.history = new CandidateEmailThreads(root)
		this.resumes = new CandidateResumeStore(root)
		this.events = new CandidateEvents(root)

		makeAutoObservable(this, {
			rootStore: false,
			getCandidateById: observable,
		})
	}

	/**
	 * Fetches a page of candidates, and stores the result in the memory
	 */
	async fetchCandidatesPage(props: CandidatesPageProps = { page: 1, rows: 10, filter: {} }) {
		const orgId = this.rootStore.organizations.activeOrganizationId

		if (!orgId) {
			return
		}

		const res = await this.requestsQueue.addUnique(
			`fetch-candidate-page:${JSON.stringify(props)}`,
			() => fetchCandidates(orgId, props)
		)

		if (!res.data) {
			return
		}

		const { result, events, positionCandidates } = res.data ?? {}

		const candidates = result.items

		runInAction(() => {
			for (const c of candidates) {
				this.addCandidate(c)
			}
			if (events) {
				const grouped = groupBy(events, 'candidateId')
				Object.keys(grouped).forEach((key) => {
					this.events.updateEvents(key, grouped[key])
				})
			}
			if (positionCandidates) {
				positionCandidates.forEach((pc) => {
					this.rootStore.positions.addPositionCandidate(pc)
				})
			}
		})

		return result
	}

	async search(
		opts: { accountId?: string; timeSelectionStatus?: CandidateTimeSelectionStatus } = {}
	) {
		const orgId = this.rootStore.organizations.activeOrganizationId

		if (!orgId) {
			return
		}

		const res = await searchCandidates(orgId, opts)

		if (!res.data) {
			return
		}

		const { candidates, positionCandidates, candidateSelections } = res.data

		const { accountId } = opts

		runInAction(() => {
			if (accountId) {
				candidates.forEach((c) => this.addCandidate({ ...c, _accountId: accountId }))
			}
			positionCandidates.forEach((pc) => this.rootStore.positions.addPositionCandidate(pc))
			candidateSelections.forEach((cs) =>
				this.rootStore.candidateSelections.addSelection(cs.candidateId, cs)
			)
		})
	}

	getQualityScore(candidateId: string) {
		const candidate = this.getCandidateById(candidateId)
		if (!candidate) {
			return 0
		}

		const activeQualityFields = this.settings.activeQualityFields
		const size = activeQualityFields.length
		if (size === 0) {
			return 0
		}

		const qualityScore = activeQualityFields.reduce((acc, field) => {
			if (field.customFieldKey) {
				if (candidate.customFields[field.customFieldKey]?.value) {
					return acc + 1
				}
				return acc
			}

			if (field.fieldName in candidate && !!candidate[field.fieldName as keyof Candidate]) {
				return acc + 1
			}

			return acc
		}, 0)

		return qualityScore / size
	}

	async getCandidateByIdAsync(id: string, priority?: number) {
		const cachedCandidate = this.getCandidateById(id)
		if (cachedCandidate) {
			return cachedCandidate
		}

		const res = await this.requestsQueue.addUnique(
			`get-candidate:${id}`,
			() => fetchCandidate(id),
			{ priority }
		)
		if (!res.data) {
			return undefined
		}
		return this.addCandidate(res.data.candidate)
	}

	getCandidateById(id: string) {
		if (!id) {
			return undefined
		}
		return this.candidatesById[id] ?? this.list.find((c) => c.id === id)
	}

	addCandidate(candidate: Candidate | ICandidate) {
		if (candidate.id in this.candidatesById) {
			this.candidatesById[candidate.id].update(candidate)
			return this.candidatesById[candidate.id]
		}

		const c = candidate instanceof Candidate ? candidate : new Candidate(candidate)
		this.list.push(c)
		this.candidatesById[candidate.id] = c

		return c
	}

	async removeCandidate(candidate: Candidate | ICandidate) {
		const orgId = this.rootStore.organizations.activeOrganizationId

		if (!orgId) {
			return false
		}

		const res = await removeCandidate(orgId, candidate.id)

		if (!res.success) {
			return false
		}

		const idx = this.list.findIndex((c) => c.id === candidate.id)
		if (idx === -1) {
			return false
		}

		this.list.splice(idx, 1)
		delete this.candidatesById[candidate.id]
		return true
	}

	async updateCandidate(candidateId: string, orgId: string, changes: Partial<CandidateChanges>) {
		const res = await updateCandidate(orgId, candidateId, changes)
		if (!res.success || !res.data) {
			return false
		}

		const { candidate } = res.data
		const existing = this.candidatesById[candidate.id]

		if (!existing) {
			// unexpected but if the case then add it
			this.addCandidate(candidate)
		} else {
			existing.update(candidate)
		}
		return true
	}

	addSequenceToCandidate(id: string, sequenceId: string) {
		const candidate = this.getCandidateById(id)
		if (!candidate) {
			return
		}

		candidate.sequences.push(sequenceId)
	}

	removeSequenceFromCandidate(id: string, sequenceId: string) {
		const candidate = this.getCandidateById(id)
		if (!candidate) {
			return
		}

		const idx = candidate.sequences.findIndex((seqId) => seqId === sequenceId)
		if (idx >= 0) {
			candidate.sequences.splice(idx, 1)
		}
	}

	clearCandidates() {
		while (this.list.length > 0) {
			this.list.pop()
		}
		Object.keys(this.candidatesById).forEach((key) => {
			delete this.candidatesById[key]
		})
	}
}
