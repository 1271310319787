import { Tooltip } from '@nextui-org/react'
import { rootStore } from '@store'
import { ICandidateStage } from '@touchpoints/requests'
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { CandidateStageColorDot } from './CandidateStageColorDot'

const StageView = ({
	stage,
	expanded,
	last,
}: {
	stage: Omit<ICandidateStage, 'id'>
	expanded: boolean
	last: boolean
}) => {
	let props = {}
	if (last) {
		props = {
			borderColor: stage.color,
			borderWidth: '2px',
			borderStyle: 'dashed',
		}
	}

	if (expanded) {
		return (
			<div
				className="flex shrink items-center px-2 m-1 space-x-2 rounded-full text-sm"
				style={{
					background: `${last ? 'white' : stage.color + '30'}`,
					...props,
				}}
			>
				<CandidateStageColorDot
					noMargin
					size="sm"
					color={stage.color}
					className="ring-transparent"
				/>
				<p className="whitespace-nowrap">{stage.name}</p>
			</div>
		)
	}

	return (
		<Tooltip
			content={
				<div className="flex space-x-2">
					<CandidateStageColorDot noMargin color={stage.color} />
					<p>{stage.name}</p>
				</div>
			}
		>
			<CandidateStageColorDot noMargin color={stage.color} />
		</Tooltip>
	)
}

const UNKNOWN_STAGE = {
	name: 'Unknown',
	color: '#000',
}
type Props = {
	currentStageId?: string
	stageHistory?: { stageId: string; ts: number }[]
	canExpand?: boolean
}
export function CandidateStageTimeline({ currentStageId, stageHistory, canExpand = false }: Props) {
	const [expanded, setExpanded] = useState(false)
	const [stage, setStage] = useState<ICandidateStage | undefined>(undefined)
	const [history, setHistory] = useState<{ stage: ICandidateStage; ts: number }[]>([])

	const handleExpand = () => {
		if (canExpand) setExpanded(!expanded)
	}

	useEffect(() => {
		if (currentStageId) {
			const stage = rootStore.stages.candidatesStageById?.[currentStageId]
			setStage(stage ?? UNKNOWN_STAGE)
		}
	}, [currentStageId])

	useEffect(() => {
		if (stageHistory) {
			const history = stageHistory.map((event) => {
				const stage = rootStore.stages.candidatesStageById?.[event.stageId] ?? UNKNOWN_STAGE
				return {
					stage,
					ts: event.ts,
				}
			})
			setHistory(history)
		}
	}, [stageHistory])

	return (
		<div
			className="flex items-center space-x-2 w-full hover:cursor-pointer"
			onClick={handleExpand}
		>
			<div
				className={clsx('flex items-center w-full', {
					'-space-x-1 rounded-md': !expanded,
					'flex-wrap': expanded,
					'hover:bg-slate-50': canExpand && !expanded,
				})}
			>
				{history.length === 0 && stage && (
					<StageView stage={stage} expanded={expanded} last={true} />
				)}
				{history.map((event, idx) => {
					return (
						<StageView
							key={event.ts}
							stage={event.stage}
							expanded={expanded}
							last={idx + 1 === history.length}
						/>
					)
				})}
				{!expanded && <p className="px-2 text-gray-500 truncate">{stage?.name}</p>}
			</div>
		</div>
	)
}
