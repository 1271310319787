import { ICandidate } from '@touchpoints/requests'

export const CandidateFirstLetterIcon: React.FunctionComponent<{
	candidate?: Partial<ICandidate>
}> = ({ candidate }: { candidate?: Partial<ICandidate> }) => {
	const firstLetter = candidate?.firstName?.[0]
	if (!firstLetter) {
		return null
	}
	return (
		<span className="w-6 h-6 leading-6 mr-3 text-center text-zinc-900 dark:text-black rounded-full bg-slate-50">
			{firstLetter?.toLocaleUpperCase()}
		</span>
	)
}
