import { isSimilar } from '@services/color'
import { rootStore } from '@store/index'
import { IPositionCandidate } from '@touchpoints/requests'
import { useEffect, useMemo } from 'react'
import { useReaction } from './mobx'

export function useSimplifiedStageColors(stageIds?: string[]) {
	const stages = useReaction(
		() => {
			if (!stageIds) {
				return rootStore.stages.candidates.map((stage) => ({ ...stage }))
			} else {
				return stageIds
					.map((id) => rootStore.stages.candidatesStageById[id])
					.filter((v) => !!v)
			}
		},
		100,
		[stageIds]
	)
	const colors = useMemo(() => {
		const list: string[] = []
		if (!stages) {
			return list
		}

		if (!stageIds) {
			list.push('#000000')
		}

		for (const stage of stages) {
			const color = stage.color ?? '#000000'

			const similarC = list.find((c) => isSimilar(color, c))

			if (similarC) {
				continue
			}

			list.push(color)
		}
		return list
	}, [stages, stageIds])

	return colors
}

export function useStageOptions(includeNone = true) {
	const options = useReaction<{ value: string; label: string; color?: string }[]>(() => {
		return [
			...rootStore.stages.candidates.map((stage) => ({
				value: stage.id,
				label: stage.name,
				color: stage.color,
			})),
			...(includeNone ? [{ value: '', label: 'None' }] : []),
		]
	})

	return options
}

export function useStageHistory(positionCandidate?: IPositionCandidate) {
	useEffect(() => {
		if (!positionCandidate?.candidateId) return
		const events = rootStore.candidates.events.getEventsForCandidate(
			positionCandidate.candidateId
		)
		if (!events) {
			rootStore.candidates.events.fetchAllEventsForCandidate(positionCandidate.candidateId)
		}
	}, [positionCandidate])

	const stageHistory = useReaction(
		() => {
			if (!positionCandidate?.candidateId || !positionCandidate?.positionId) {
				return []
			}
			return (
				(
					rootStore.candidates.events.getEventsForCandidate(
						positionCandidate.candidateId
					) ?? []
				)
					.filter(
						(e) =>
							e.name === 'stage-changed' &&
							e.positionId === positionCandidate.positionId
					)
					.filter((e) => !e.deleted)
					.sort((a, b) => a.ts - b.ts)
					.map((e) => ({
						ts: e.ts,
						stageId: (e.payload?.stage ?? '') as string,
					}))
					.filter((e) => !!e.stageId)
					// only show last X events
					.slice(-10)
			)
		},
		500,
		[positionCandidate]
	)

	return positionCandidate ? stageHistory : []
}
