import {
	GetMethodFunc,
	IAccount,
	IPosition,
	IPositionCandidate,
	PatchMethodFunc,
	PutMethodFunc,
} from '../types'

export function makeFetchPositions(
	getMethod: GetMethodFunc,
	authHeader: () => Promise<Record<string, string>>
) {
	return async function fetchPositions(organizationId: string) {
		const headers = await authHeader()
		return getMethod<{ positions: IPosition[]; accountsById: Record<string, IAccount> }>(
			`/organizations/${organizationId}/positions`,
			undefined,
			headers
		)
	}
}

export function makeAddCandidateToPosition(
	putMethod: PutMethodFunc,
	authHeader: () => Promise<Record<string, string>>
) {
	return async function addCandidateToPosition(
		orgId: string,
		accountId: string,
		positionId: string,
		candidateId: string
	) {
		const headers = await authHeader()
		return putMethod<{ positionCandidate: IPositionCandidate }>(
			`/organizations/${orgId}/accounts/${accountId}/positions/${positionId}/candidates`,
			{ candidateId },
			headers
		)
	}
}

export function makeUpdateCandidatePosition(
	patchMethod: PatchMethodFunc,
	authHeader: () => Promise<Record<string, string>>
) {
	return async function updateCandidatePosition(
		orgId: string,
		accountId: string,
		positionId: string,
		candidateId: string,
		data: Partial<
			Pick<
				IPositionCandidate,
				| 'stage'
				| 'engagementContactId'
				| 'recruiterId'
				| 'accountExecutiveId'
				| 'supportContactId'
				| 'status'
				| 'trelloCardUrl'
				| 'tags'
			>
		>
	) {
		const headers = await authHeader()
		return patchMethod<{ positionCandidate: IPositionCandidate }>(
			`/organizations/${orgId}/accounts/${accountId}/positions/${positionId}/candidates/${candidateId}`,
			data,
			headers
		)
	}
}

export function makeFetchPositonCandidatesForCandidate(
	getMethod: GetMethodFunc,
	authHeader: () => Promise<Record<string, string>>
) {
	return async function fetchPositionCandidatesForCandidate(
		orgId: string,
		candidateId: string,
		opts = { includeAccounts: false }
	) {
		const headers = await authHeader()
		return getMethod<{
			positionCandidates: IPositionCandidate[]
			positions: IPosition[]
			accounts?: IAccount[]
		}>(`/organizations/${orgId}/candidates/${candidateId}/positions`, opts, headers)
	}
}
