import { Loading } from '@nextui-org/react'
import { rootStore } from '@store'
import type { ICard } from '@touchpoints/requests'

import type { BoardEntry } from '@types'
import { Dictionary, groupBy } from 'lodash'
import { useRouter } from 'next/router'
import { useEffect, useMemo, useState } from 'react'
import { BoardColumn } from './BoardColumn'
import { BoardList } from './BoardList'
import { useBoardLayout } from './hooks'
import { store } from './store'

export interface BoardViewWrapperProps {
	cards: ICard[]
	loadingCards?: boolean
	renderLoadingCards?: number
	onClick?: (boardCard: ICard) => void
}

export function BoardViewWrapper({
	cards,
	loadingCards = false,
	renderLoadingCards,
	onClick,
}: BoardViewWrapperProps) {
	const router = useRouter()
	const params = useMemo(() => (router.query.params ?? []) as string[], [router])
	const positionCandidateId = useMemo(() => {
		if (params.length === 3) {
			// /accounts/:accountId/board/:positionCandidateId
			return params[2]
		}

		return undefined
	}, [params])
	const [positionId, candidateId] = useMemo(() => {
		if (params.length < 5) {
			return []
		}

		// /:accountId/board/:positionId/candidates/:candidateId
		return [params[2], params[4]]
	}, [params])

	useEffect(() => {
		const id = setTimeout(() => {
			if (!positionCandidateId && positionId && candidateId) {
				const pc = rootStore.positions.getPositionCandidate(candidateId, positionId)
				if (pc) {
					store.setSelectedPositionCandidateId(pc.id)
					return
				}

				rootStore.positions
					.fetchPositionCandidate(positionId, candidateId, {
						priority: 1,
					})
					.then((res) => {
						if (!res) {
							return
						}

						store.setSelectedPositionCandidateId(res.id)
					})
			} else if (positionCandidateId) {
				store.setSelectedPositionCandidateId(positionCandidateId)
			}
		}, 500)
		return () => clearTimeout(id)
	}, [positionCandidateId, positionId, candidateId])

	if (loadingCards && !renderLoadingCards) {
		return <Loading size="lg" className="mt-10" />
	}

	return (
		<div className="flex w-full h-full">
			<BoardView
				cards={cards}
				loadingCards={loadingCards}
				renderLoadingCards={renderLoadingCards}
				onClick={onClick}
			/>
		</div>
	)
}
export interface BoardViewProps {
	cards: ICard[]
	loadingCards: boolean
	renderLoadingCards?: number
	onClick?: (boardCard: ICard) => void
}

const fakeCandidate = (): BoardEntry => {
	return {
		loading: true,
	}
}

export function BoardView({ cards, loadingCards, renderLoadingCards, onClick }: BoardViewProps) {
	const [cardsByPosition, setCardsByPosition] = useState<Dictionary<BoardEntry[]> | undefined>(
		undefined
	)
	const [layout] = useBoardLayout()

	useEffect(() => {
		const grouped = groupBy(
			cards.map((bc) => {
				return {
					card: bc,
					loading: false,
				}
			}),
			(e) => e.card.position.id
		) as Dictionary<BoardEntry[]>

		if (!renderLoadingCards || renderLoadingCards < 1) {
			setCardsByPosition(grouped)
			return
		}

		const keys = Object.keys(grouped)
		let counter = renderLoadingCards

		while (counter > 0 && keys.length > 0) {
			const index = counter % keys.length
			grouped[keys[index]].push(fakeCandidate())
			counter--
		}

		setCardsByPosition(grouped)
	}, [cards, renderLoadingCards])

	const sortedEntiresByPosition = useMemo(() => {
		if (!cardsByPosition) {
			return []
		}

		return Object.keys(cardsByPosition).sort((a, b) => {
			const aPosition = rootStore.positions.getPositionById(a)
			const bPosition = rootStore.positions.getPositionById(b)
			if (!aPosition || !bPosition) {
				return 0
			}
			return bPosition.referenceNumber - aPosition.referenceNumber
		})
	}, [cardsByPosition])

	return (
		<div className="flex flex-col w-full h-full">
			{cardsByPosition === undefined ? (
				<div className="flex w-full h-48 justify-center items-center">
					<p className="text-base text-slate-700">No positions</p>
				</div>
			) : layout === 'kanban' ? (
				<div className="w-full h-full overflow-x-auto scroll-pl-16 snap-x">
					<div className="flex w-full h-full">
						<div className="w-96 grid grid-flow-col gap-3">
							{sortedEntiresByPosition.map((positionId) => {
								return (
									<BoardColumn
										key={positionId}
										positionId={positionId}
										loadingCandidates={loadingCards}
										cards={cardsByPosition[positionId]}
										onClick={onClick}
									/>
								)
							})}
						</div>
					</div>
				</div>
			) : (
				<div className="flex flex-col w-full h-full">
					{sortedEntiresByPosition.map((positionId) => {
						return (
							<BoardList
								key={positionId}
								positionId={positionId}
								loadingCandidates={loadingCards}
								boardEntries={cardsByPosition[positionId]}
								onClick={onClick}
							/>
						)
					})}
				</div>
			)}
		</div>
	)
}
