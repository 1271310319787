import { rootStore } from '@store/index'
import { useReaction } from '@touchpoints/mobx-hooks'
import { ITag } from '@touchpoints/requests'
import { useCustomFilterableInput } from '@touchpoints/ui'
import clsx from 'clsx'

import { colorContrast, hexToRgba } from '@components/shared/OrgUserAvatar'
import { Dropdown } from '@touchpoints/ui'
import Fuse from 'fuse.js'
import { observer } from 'mobx-react-lite'
import { ReactNode, useEffect, useRef, useState } from 'react'
import { HiPlusSmall } from 'react-icons/hi2'
import { MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank } from 'react-icons/md'

type TagSelectProps = {
	value: ITag[]
	onChange: (tags: ITag[]) => void
	isDisabled?: boolean
}
export const TagSelect = observer(function ({ value, onChange, isDisabled }: TagSelectProps) {
	const [open, setOpen] = useState(false)
	const [tagOptions, setTagOptions] = useState<
		{ value: string; label: ReactNode; searchString: string; tag: ITag }[]
	>([])

	const ref = useRef<HTMLInputElement>(null)

	const baseOptions = useReaction(() =>
		rootStore.candidates.settings.tags.map((u) => ({
			value: u.id,
			label: (
				<div className="flex items-center space-x-2">
					<div
						className="w-3 h-3 rounded-lg"
						style={{
							background: u?.color ?? '#000000',
						}}
					></div>
					<p className="text-base flex-grow px-2">{u.name}</p>
				</div>
			),
			searchString: u.name,
			tag: u,
		}))
	)

	const [search, setSearch] = useState(
		new Fuse<{ value: string; label: ReactNode; searchString: string; tag: ITag }>(
			baseOptions,
			{
				keys: ['searchString'],
			}
		)
	)

	const { query, wrapperRef, onFocus, onBlur } = useCustomFilterableInput()

	useEffect(() => {
		// Autofocus the input when the dropdown opens
		const timeoutId = setTimeout(() => {
			wrapperRef.current?.focus()
		}, 50)
		return () => {
			clearTimeout(timeoutId)
		}
	}, [open, wrapperRef])

	useEffect(() => {
		setSearch(
			new Fuse<{ value: string; label: ReactNode; searchString: string; tag: ITag }>(
				baseOptions,
				{
					keys: ['searchString'],
				}
			)
		)
	}, [baseOptions])

	useEffect(() => {
		search?.setCollection(baseOptions)
	}, [baseOptions, search])

	useEffect(() => {
		if (!query) {
			setTagOptions([...baseOptions])
			return
		}

		const id = setTimeout(() => {
			if (!search) {
				return
			}

			const res = search.search(query)
			const items = res.map((r) => r.item)
			setTagOptions(items)

			ref.current?.focus()
		}, 300)

		return () => {
			clearTimeout(id)
		}
	}, [search, query, baseOptions])

	return (
		<div>
			<Dropdown
				side="bottom"
				align="start"
				open={open}
				onCloseAutoFocus={() => setOpen(false)}
				onEscapeKeyDown={() => setOpen(false)}
				onPointerDownOutside={() => setOpen(false)}
				onFocusOutside={() => setOpen(false)}
				onInteractOutside={() => setOpen(false)}
				trigger={
					<div
						className={clsx(
							'text-slate-400 px-2 h-[34px] flex flex-row items-center dark:bg-slate-700 dark:border-slate-700 hover:bg-slate-50',
							{
								'cursor-pointer': !isDisabled,
								'cursor-not-allowed': isDisabled,
								'pointer-events-none': isDisabled,
								'bg-gray-50': isDisabled,
							}
						)}
						onClick={() => setOpen(!open)}
					>
						<HiPlusSmall className="text-xl" />
						<span className="font-light">Add tag</span>
					</div>
				}
			>
				<div className="w-[300px]">
					<div className="sticky top-0 bg-white border-gray-200 border-t border-x rounded-t-[10px]">
						<div className="flex p-2">
							<input
								ref={wrapperRef}
								type="text"
								className={clsx(
									'w-full text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block p-1 dark:placeholder-gray-400 dark:text-white border-0 ring-0'
								)}
								aria-label="filter tags"
								placeholder={'Filter tags'}
								value={query}
								onFocus={onFocus}
								onBlur={onBlur}
								onChange={() => {
									//do nothing...
								}}
							/>
						</div>
						<hr className="h-px bg-gray-200 border-0 dark:bg-gray-700"></hr>
					</div>

					<div className="border-x border-b rounded-b-[10px] -mt-1.5">
						{tagOptions.map((tag, index) => {
							const checked = value.some((v) => v.id === tag.value)
							return (
								<Dropdown.CheckboxItem
									key={`${tag.value}-${index}`}
									checked={checked}
									onCheckedChange={(checked) => {
										if (checked) {
											onChange([...value, tag.tag])
										} else {
											onChange(value.filter((v) => v.id !== tag.value))
										}
									}}
								>
									<div className="flex items-center px-2">
										<div>
											{checked ? (
												<MdOutlineCheckBox className="w-5 h-5" />
											) : (
												<MdOutlineCheckBoxOutlineBlank className="w-5 h-5" />
											)}
										</div>
										<CustomDropdownItem key={`tag-${index}`} tag={tag.tag} />
									</div>
								</Dropdown.CheckboxItem>
							)
						})}
					</div>
				</div>
			</Dropdown>
		</div>
	)
})

const CustomDropdownItem = observer(({ tag }: { tag: ITag }) => {
	return (
		<div className="p-1 truncate rounded-lg m-1 cursor-pointer">
			<Tag tag={tag} />
		</div>
	)
})

export const Tag = observer(function Tag({ tag }: { tag: ITag }) {
	const color = tag.color ?? '#000000'
	return (
		<div
			className="flex items-center space-x-2 w-fit rounded-full px-2 "
			style={{
				backgroundColor: hexToRgba(color, 0.7),
			}}
		>
			<p
				className="text-sm flex-grow px-2"
				style={{
					color: colorContrast(color),
				}}
			>
				{tag.name}
			</p>
		</div>
	)
})
