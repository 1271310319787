export function SnippetsIcon({ stroke = '#687076' }: { stroke?: string }) {
	return (
		<svg
			width="12"
			height="12"
			viewBox="0 0 12 12"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M0.666687 1.99999V9.99999M3.33335 1.33332V10.6667M6.00002 2.04102V9.95895C6.00002 10.8264 6.81521 11.4629 7.65673 11.2525L10.3234 10.5858C10.917 10.4374 11.3334 9.90411 11.3334 9.29228V2.70769C11.3334 2.09586 10.917 1.56255 10.3234 1.41416L7.65673 0.747498C6.8152 0.537116 6.00002 1.17359 6.00002 2.04102Z"
				stroke={stroke}
				strokeWidth="1.2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	)
}
