import EmojiPicker from 'emoji-picker-react'
import { useState } from 'react'
import { HiChevronDown, HiChevronUp } from 'react-icons/hi'
import { Dropdown } from './core/Dropdown'
import { IEmojiProps } from '@touchpoints/requests'

type EmojiSelectProps = {
	emojiProps?: IEmojiProps
	onEmojiSelected: ({ emoji, emojiUrl }: { emoji: string; emojiUrl: string }) => void
}
export const EmojiSelect = ({ emojiProps, onEmojiSelected }: EmojiSelectProps) => {
	const [open, setOpen] = useState(false)
	const [url, setUrl] = useState(emojiProps?.emojiUrl ?? '')

	return (
		<Dropdown
			side="bottom"
			align="start"
			open={open}
			className="h-full tw-bg-slate-400"
			onCloseAutoFocus={() => setOpen(false)}
			onEscapeKeyDown={() => setOpen(false)}
			onPointerDownOutside={() => setOpen(false)}
			onFocusOutside={() => setOpen(false)}
			onInteractOutside={() => setOpen(false)}
			trigger={
				<div
					className="cursor-pointer h-auto flex items-center space-x-2 border border-slate-300 rounded-md px-1 py-1 w-fit"
					onClick={() => setOpen(!open)}
				>
					<div className="w-8 h-8 ">
						{url && <img src={url} className="" alt={emojiProps?.emoji ?? ''} />}
					</div>
					<div>
						{open && <HiChevronUp />}
						{!open && <HiChevronDown />}
					</div>
				</div>
			}
		>
			<EmojiPicker
				onEmojiClick={(e) => {
					setOpen(false)
					onEmojiSelected({ emoji: e.emoji, emojiUrl: e.imageUrl })
					setUrl(e.imageUrl)
				}}
				width={'400px'}
				height={'500px'}
				open={open}
			/>
		</Dropdown>
	)
}
