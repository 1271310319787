import { ICandidate, IPositionCandidate, PostMethodFunc } from '../types'

export function makeSaveSourcedCandidate(
	postMethod: PostMethodFunc,
	authHeader: () => Promise<Record<string, string>>
) {
	return async function saveSourcedCandidate(
		orgId: string,
		linkedinUrl: string,
		positionId: string,
		options: { linkedinRecruiterUrl?: string; tags?: string[] } = {}
	) {
		const headers = await authHeader()
		return postMethod<{ output?: string; candidate?: ICandidate; card?: IPositionCandidate }>(
			`/organizations/${orgId}/sourcing`,
			{ linkedinUrl, positionId, ...options },
			headers
		)
	}
}
