import { Radio } from '@nextui-org/react'
import {
	Condition,
	FieldType,
	Operator,
	OperatorType,
	Query,
	Field as QueryField,
	instanceOfQuery,
} from '@touchpoints/requests'

import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import { PropsWithChildren, ReactNode, useEffect, useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import { HiPlus } from 'react-icons/hi'
import { PiTrash } from 'react-icons/pi'
import { QueryBuilderOption, QueryBuilderSelect } from './QueryBuilderSelect'
import { cloneDeep } from 'lodash'

export const universalOperators: Operator[] = [
	{
		label: 'Is Empty',
		type: 'IsEmpty',
	},
	{
		label: 'Is Not Empty',
		type: 'IsNotEmpty',
	},
]

export const stringOperators: Operator[] = [
	{
		label: 'Contains',
		type: 'Contains',
	},
	{
		label: 'Does not contain',
		type: 'DoesNotContain',
	},
	{
		label: 'Is',
		type: 'Is',
	},
	{
		label: 'Is Not',
		type: 'IsNot',
	},
	...universalOperators,
]

export const numberOperators: Operator[] = [
	{
		label: 'Equal',
		type: 'Equal',
	},
	{
		label: 'Not Equal',
		type: 'NotEqual',
	},
	{
		label: 'Less Than',
		type: 'LessThan',
	},
	{
		label: 'Less Than or Equal',
		type: 'LessThanOrEqual',
	},
	{
		label: 'Greater Than',
		type: 'GreaterThan',
	},
	{
		label: 'Greater Than or Equal',
		type: 'GreaterThanOrEqual',
	},
	...universalOperators,
]

export const booleanOperators: Operator[] = [
	{
		label: 'Is',
		type: 'Is',
	},
	{
		label: 'Is Not',
		type: 'IsNot',
	},
	...universalOperators,
]

export const selectOperators: Operator[] = [
	{
		label: 'Contains',
		type: 'Contains',
	},
	{
		label: 'Does not contain',
		type: 'DoesNotContain',
	},
	...universalOperators,
]

export const dateOperators: Operator[] = [...numberOperators]

const operatorsByType: Record<FieldType, Operator[]> = {
	string: stringOperators,
	number: numberOperators,
	boolean: booleanOperators,
	date: dateOperators,
	select: selectOperators,
}

export interface Field extends QueryField {
	selectOptions?: SelectOptionProps
}

export interface SelectOptionProps {
	options: { label: string | ReactNode; value: string; searchString?: string }[]
	isMulti?: boolean
	filterable?: boolean
	inputPlaceholder?: string
}

type Props = {
	fields: Field[]
	query: Query
	onQueryChanged: (query: Query) => void
}
export const QueryBuilder = observer(function ({ fields, query, onQueryChanged }: Props) {
	const [changes, setChanges] = useState<Query | undefined>(undefined)
	const [tempQuery, setTempQuery] = useState<Query>(cloneDeep(query))

	const handleQueryChanged = (q: Query) => {
		setChanges(q)
		setTempQuery(q)
	}

	return (
		<div className="max-w-[900px] min-w-[500px] bg-white border shadow-md rounded-md">
			<QueryComponent
				fields={fields}
				query={tempQuery}
				onQueryChanged={handleQueryChanged}
				canBeDeleted={false}
			>
				{changes && (
					<div className="p-1 flex justify-end">
						<button
							className="px-2 py-1 rounded-md font-light text-white bg-blue-600 hover:bg-blue-500 cursor-pointer"
							onClick={() => {
								onQueryChanged(changes)
								setChanges(undefined)
							}}
						>
							Apply
						</button>
					</div>
				)}
			</QueryComponent>
		</div>
	)
})

type QueryComponentProps = {
	fields: Field[]
	query: Query
	onQueryChanged: (c: Query) => void
	onQueryDeleted?: () => void
	canBeDeleted: boolean
	depth?: number
}
export const QueryComponent = function ({
	fields,
	query,
	onQueryChanged,
	onQueryDeleted,
	canBeDeleted,
	depth = 1,
	children,
}: PropsWithChildren<QueryComponentProps>) {
	const handleAddCondition = () => {
		onQueryChanged?.({
			conjunction: query?.conjunction ?? 'and',
			conditions: [...(query?.conditions ?? []), {}],
		})
	}
	const handleAddConditionGroup = () => {
		onQueryChanged?.({
			conjunction: query?.conjunction,
			conditions: [
				...(query?.conditions ?? []),
				{
					conjunction: 'and',
					conditions: [{}],
				},
			],
		})
	}

	return (
		<div
			className={clsx('p-3 flex flex-col space-y-3', {
				'border bg-slate rounded-md shadow-md': canBeDeleted,
				'bg-slate-50': depth === 2,
				'bg-slate-100': depth === 3,
			})}
		>
			{canBeDeleted && (
				<div className="flex justify-between">
					<p className="text-sm">
						{query.conjunction === 'and' ? 'All' : 'Any'} of the followings are true
					</p>
					<button className="p-1 text-slate-500 hover:cursor-pointer hover:text-black dark:hover:text-slate-200">
						<PiTrash size={20} onClick={onQueryDeleted} />
					</button>
				</div>
			)}
			{query?.conditions.map((condition, idx: number) => {
				const isFirst = idx === 0

				const conjunctionOptions = [
					{ value: 'and', label: 'and' },
					{ value: 'or', label: 'or' },
				]

				return (
					<div key={`${idx}`} className="flex space-x-1 items-center">
						<div className="w-20">
							{isFirst && <span>Where</span>}
							{!isFirst && (
								<QueryBuilderSelect
									options={conjunctionOptions}
									value={conjunctionOptions.filter(
										(e) => e.value === query.conjunction
									)}
									onChange={(v) => {
										const val = v as QueryBuilderOption
										onQueryChanged({
											...query,
											conjunction: val.value === 'and' ? 'and' : 'or',
										})
									}}
								/>
							)}
						</div>
						{instanceOfQuery(condition) && (
							<div className="w-full">
								<QueryComponent
									fields={fields}
									query={condition}
									onQueryChanged={(q) => {
										const conditions = query.conditions
										conditions[idx] = q
										onQueryChanged({
											...query,
											conditions,
										})
									}}
									canBeDeleted={true}
									onQueryDeleted={() => {
										onQueryChanged({
											...query,
											conditions: query.conditions.filter(
												(c, index) => index != idx
											),
										})
									}}
									depth={depth + 1}
								/>
							</div>
						)}
						{!instanceOfQuery(condition) && (
							<ConditionComponent
								condition={condition as Condition}
								onConditionChanged={(c) => {
									const conditions = query.conditions
									conditions[idx] = c
									onQueryChanged({
										...query,
										conditions,
									})
								}}
								onConditionDeleted={() => {
									onQueryChanged({
										...query,
										conditions: query.conditions.filter(
											(c, index) => index != idx
										),
									})
								}}
								fields={fields}
							/>
						)}
					</div>
				)
			})}
			<div className="flex justify-between">
				<div className="flex items-center space-x-2">
					<button
						className={clsx(
							'font-light text-sm text-[#687076] hover:bg-slate-50 rounded-md p-1 flex items-center space-x-2',
							{
								'hover:bg-white': depth === 2,
							}
						)}
						onClick={handleAddCondition}
					>
						<HiPlus /> Add Condition
					</button>
					{depth < 3 && (
						<button
							className={clsx(
								'font-light text-sm text-[#687076] hover:bg-slate-50 rounded-md p-1 flex items-center space-x-2',
								{
									'hover:bg-white': depth === 2,
								}
							)}
							onClick={handleAddConditionGroup}
						>
							<HiPlus /> Add Condition Group
						</button>
					)}
				</div>
				{children}
			</div>
		</div>
	)
}

type ConditionProps = {
	fields: Field[]
	condition: Condition
	onConditionChanged: (c: Condition) => void
	onConditionDeleted: () => void
}
const ConditionComponent = function ({
	fields,
	condition,
	onConditionChanged,
	onConditionDeleted,
}: ConditionProps) {
	const [operatorOptions, setOperatorOptions] = useState<{ value: string; label: string }[]>([])
	const fieldOptions = fields.map((c) => {
		return {
			value: c.id,
			label: c.label,
		}
	})

	useEffect(() => {
		if (!condition.field) return

		const fieldSelected = fields.find((o) => o.id === condition.field)
		if (!fieldSelected) return

		if (fieldSelected.type === 'select' && !fieldSelected.selectOptions?.isMulti) {
			setOperatorOptions(
				operatorsByType['boolean'].map((o) => {
					return {
						value: o.type,
						label: o.label,
					}
				}) ?? []
			)
			return
		}
		setOperatorOptions(
			operatorsByType[fieldSelected?.type].map((o) => {
				return {
					value: o.type,
					label: o.label,
				}
			}) ?? []
		)
	}, [condition, fields])

	const renderValueComponent = (field: string | undefined) => {
		if (!field) return <></>
		if (condition.operator === 'IsEmpty' || condition.operator === 'IsNotEmpty') return <></>

		const fieldSelected = fields.find((o) => o.id === field)
		switch (fieldSelected?.type) {
			case 'string': {
				return (
					<input
						className="w-full h-[38px] p-3 rounded-md border font-light"
						value={condition.value as string}
						aria-label="query value"
						onChange={(e) => {
							onConditionChanged?.({
								...condition,
								value: e.currentTarget.value,
							})
						}}
					/>
				)
			}
			case 'boolean': {
				return (
					<div className="mt-2 ml-2">
						<Radio.Group
							aria-label="bool query value"
							defaultValue="true"
							orientation="horizontal"
							onChange={(e) => {
								onConditionChanged?.({
									...condition,
									value: JSON.parse(e),
								})
							}}
						>
							<Radio value="true" size="xs" isSquared>
								True
							</Radio>
							<Radio value="false" size="xs" isSquared>
								False
							</Radio>
						</Radio.Group>
					</div>
				)
			}
			case 'date': {
				return (
					<div className="mt-2 ml-2">
						<ReactDatePicker
							selected={condition.value as Date}
							dateFormat="MMM d, yyyy"
							onChange={(date) => {
								onConditionChanged?.({
									...condition,
									value: date,
								})
							}}
							popperClassName="z-[1000]"
							className="w-full"
						/>
					</div>
				)
			}
			case 'number': {
				return (
					<input
						className="w-full h-[38px] p-3 rounded"
						value={condition.value as string}
						aria-label="query value"
						onChange={(e) => {
							onConditionChanged?.({
								...condition,
								value: Number(e.currentTarget.value),
							})
						}}
						onKeyPress={(event) => {
							if (!/[0-9]/.test(event.key)) {
								event.preventDefault()
							}
						}}
					/>
				)
			}
			case 'select': {
				const fieldOptions = fieldSelected.selectOptions?.options || []
				const conditionValue = Array.isArray(condition.value)
					? condition.value
					: [condition.value]

				const val = fieldOptions.filter((f) => conditionValue.some((e) => e === f.value))

				return (
					<QueryBuilderSelect
						options={fieldOptions}
						value={val}
						triggerClassName={'w-64'}
						contentClassName="p-0"
						onChange={(changed) => {
							let value
							if (Array.isArray(changed)) {
								value = changed.map((e) => e.value)
							} else {
								value = (changed as unknown as any).value
							}
							onConditionChanged?.({
								...condition,
								value: value,
							})
						}}
						isMulti={fieldSelected.selectOptions?.isMulti}
						filterable={fieldSelected.selectOptions?.filterable}
						inputPlaceholder={fieldSelected.selectOptions?.inputPlaceholder}
					/>
				)
			}
		}
	}

	return (
		<div className="flex justify-between items-center">
			<div className="w-full flex space-x-1 items-center">
				<QueryBuilderSelect
					options={fieldOptions}
					value={fieldOptions.filter((o) => o.value === condition.field)}
					triggerClassName={'w-64'}
					onChange={(v) => {
						const val = v as QueryBuilderOption

						// By default, and if it's possible, set operator to Contains
						let operator = undefined
						const fieldSelected = fields.find((o) => o.id === val.value)
						if (fieldSelected && operatorsByType[fieldSelected?.type]) {
							const contains = operatorsByType[fieldSelected?.type].find(
								(o) => o.type === 'Contains'
							)
							operator = contains?.type
						}

						console.log(operator)

						onConditionChanged?.({
							field: val.value,
							operator,
							value: undefined,
						})
					}}
				/>

				{condition.field && (
					<>
						<QueryBuilderSelect
							options={operatorOptions}
							value={operatorOptions.filter((o) => o.value === condition.operator)}
							triggerClassName={'w-26'}
							onChange={(v) => {
								const val = v as QueryBuilderOption
								onConditionChanged?.({
									...condition,
									operator: (val.value as OperatorType) || '',
								})
							}}
						/>

						{renderValueComponent(condition.field)}
					</>
				)}
			</div>
			<span className="p-1 text-slate-500 hover:cursor-pointer hover:text-black dark:hover:text-slate-200">
				<PiTrash size={20} onClick={() => onConditionDeleted()} />
			</span>
		</div>
	)
}
