import truncate from 'lodash/truncate'
import { reaction } from 'mobx'
import { observer } from 'mobx-react-lite'

import { PropsWithChildren, useEffect, useState } from 'react'

import { createSelectOnChange, Label, Select, Tabs, toast } from '@touchpoints/ui'

import { Button, Input } from '@nextui-org/react'
import { rootStore } from '@store'
import { HiChevronDown, HiChevronUp, HiDuplicate, HiSearch } from 'react-icons/hi'
import JSONPretty from 'react-json-pretty'

import { QueryBuilder } from '@components/shared/QueryBuilder'
import { candidateQueryStructure } from '@components/shared/QueryBuilderFields'
import { ICandidate, IPosition, Query } from '@touchpoints/requests'
import clsx from 'clsx'
import { isEmpty } from 'lodash'
import { PositionSelect } from './PositionSelect'

type Props = {
	subject?: string
	html: string
}
export function PreviewPanel({ subject, html, children }: PropsWithChildren<Props>) {
	return (
		<div className="flex flex-col w-full mx-auto items-center space-y-2 pt-2">
			<div className="flex w-full justify-start">
				<p className="text-lg font-semibold">Preview</p>
			</div>
			{subject && (
				<div className="flex w-full justify-start border-2 rounded-md p-3">
					<b>Subject:&nbsp;</b>
					<p className="text-base" dangerouslySetInnerHTML={{ __html: subject }}></p>
				</div>
			)}
			<div className="flex w-full border-2 rounded-md">
				<div
					id="template-preview"
					className="prose max-w-prose p-5 max-h-[300px] overflow-y-auto"
					dangerouslySetInnerHTML={{ __html: html }}
				></div>
			</div>

			{children}
		</div>
	)
}

enum Tab {
	Info = 'info',
	Variables = 'variables',
}

type OptionProps = {
	candidateId: string
	onCandidateIdChanged?: (id: string) => void
	positionId: string
	onPositionIdChanged?: (id: string) => void
	query?: Query
	onQueryChanged?: (query: Query) => void
	context?: object
	onSelectVariable?: (variable: string) => void
}
export const PreviewPanelOptions = observer(function ({
	candidateId,
	positionId,
	query,
	onCandidateIdChanged,
	onPositionIdChanged,
	onQueryChanged,
	context,
	children,
	onSelectVariable,
}: PropsWithChildren<OptionProps>) {
	const [tab, setTab] = useState(Tab.Info)

	return (
		<Tabs value={tab} onValueChange={(e) => setTab(e as Tab)}>
			<Tabs.Bar>
				<Tabs.Item value={Tab.Info}>Info</Tabs.Item>
				<Tabs.Item value={Tab.Variables} disabled={!context}>
					Variables
				</Tabs.Item>
			</Tabs.Bar>
			<Tabs.Content value={Tab.Info}>
				<PreviewPanelInfo
					candidateId={candidateId}
					onCandidateIdChanged={onCandidateIdChanged}
					positionId={positionId}
					onPositionIdChanged={onPositionIdChanged}
					query={query}
					onQueryChanged={onQueryChanged}
				>
					{children}
				</PreviewPanelInfo>
			</Tabs.Content>
			{context && (
				<Tabs.Content value={Tab.Variables}>
					<div className="flex overflow-auto overflow-x-visible">
						<VariablesDrawer context={context} onSelectVariable={onSelectVariable} />
					</div>
				</Tabs.Content>
			)}
		</Tabs>
	)
})

export function PreviewPanelInfo({
	positionId,
	candidateId,
	query,
	onCandidateIdChanged,
	onPositionIdChanged,
	onQueryChanged,
	children,
}: PropsWithChildren<Omit<OptionProps, 'context' | 'onSelectVariable'>>) {
	const [candidateOptions, setCandidateOptions] = useState<{ value: string; label: string }[]>([])

	useEffect(() => {
		if (candidateId) {
			return
		}

		const action = (list: ICandidate[]) => {
			const id = list[0]?.id
			if (!id) {
				return
			}
			onCandidateIdChanged?.(id)
		}

		action(rootStore.candidates.list)

		return reaction(() => rootStore.candidates.list.map((c) => ({ ...c })), action)
	}, [candidateId, onCandidateIdChanged])

	useEffect(() => {
		if (positionId) {
			return
		}

		const action = (list: IPosition[]) => {
			const id = list[0]?.id
			if (!id) {
				return
			}
			onPositionIdChanged?.(id)
		}

		action(rootStore.positions.list)

		return reaction(() => rootStore.positions.list.map((c) => ({ ...c })), action)
	}, [positionId, onPositionIdChanged])

	useEffect(() => {
		if (!positionId) {
			return
		}

		rootStore.positions.fetchCardsForPosition(positionId).then(async (cards) => {
			const candidates = cards.map((c) => c.candidate)
			setCandidateOptions(
				candidates.map((c) => ({
					value: c.id,
					label: `${c.email} (${c.firstName} ${c.lastName})`,
				}))
			)
			if (candidates.length > 0) {
				onCandidateIdChanged?.(candidates[0].id)
			}
		})
	}, [positionId, onCandidateIdChanged])

	const structure = candidateQueryStructure({
		positions: rootStore.positions._list,
		organizationUsers: rootStore.organizationUsers.usersForActiveOrg,
		stages: rootStore.stages.candidates,
	})

	return (
		<div className="flex w-full flex-col space-y-3">
			<PositionSelect positionId={positionId} onPositionIdChanged={onPositionIdChanged} />
			<Select
				value={candidateOptions.find((o) => o.value === candidateId)}
				label="Candidate"
				menuPlacement="top"
				options={candidateOptions}
				onChange={createSelectOnChange((v) => {
					onCandidateIdChanged?.(v)
				})}
			/>
			{query && onQueryChanged && (
				<div className="w-full flex flex-col space-y-1">
					<Label>Query</Label>
					<QueryBuilder
						query={query}
						onQueryChanged={onQueryChanged}
						fields={structure}
					/>
				</div>
			)}

			{children}
		</div>
	)
}

function StringValue({ value }: { value: string }) {
	return (
		<span className="text-sm truncate text-ellipsis">
			{truncate(value, { length: 40, omission: '...' })}
		</span>
	)
}
function ObjectValue({ value }: { value: object }) {
	const [expanded, setExpanded] = useState(false)
	const isEmpty = Array.isArray(value) ? value.length === 0 : Object.keys(value).length === 0

	return (
		<div className="flex w-full justify-between">
			{!expanded && (
				<span className="max-w-[400px] text-sm truncate">{JSON.stringify(value)}</span>
			)}
			{expanded && (
				<div className="max-w-[300px] truncate">
					<JSONPretty data={value} />
				</div>
			)}

			{!isEmpty && (
				<div
					className="p-1 hover:bg-slate-200 w-7 h-7 rounded-md hover:cursor-pointer"
					onClick={(e) => {
						e.preventDefault()
						e.stopPropagation()
						setExpanded(!expanded)
					}}
				>
					{expanded && <HiChevronDown size={20} />}
					{!expanded && <HiChevronUp size={20} />}
				</div>
			)}
		</div>
	)
}

const topLevelKeys = [
	'candidate',
	'position',
	'account',
	'sender',
	'recruiter',
	'accountExecutive',
	'queryResults',
] as const
type Keys = (typeof topLevelKeys)[number] | 'root'

export type VariablesDrawerProps = {
	context?: Record<string, any>
	onSelectVariable?: (key: string) => void
}
export function VariablesDrawer({ context, onSelectVariable }: VariablesDrawerProps) {
	const [groups, setGroups] = useState<Record<string, Record<string, any>>>({})
	const [selectedKey, setSelectedKey] = useState<string>('root')
	const [needle, setNeedle] = useState('')

	useEffect(() => {
		const topLevelKeysSet = new Set<string>(topLevelKeys)

		const buckets: Record<Keys, Record<string, any>> = {
			root: {},
			candidate: {},
			position: {},
			account: {},
			sender: {},
			recruiter: {},
			accountExecutive: {},
			queryResults: {},
		}

		for (const key in context) {
			const value = context[key]
			if (topLevelKeysSet.has(key)) {
				buckets[key as Keys] = value
			} else {
				buckets.root = {
					...buckets.root,
					[key]: value,
				}
			}
		}

		setGroups(buckets)
	}, [context])

	const handleSelectKey = (key: string, val: any) => {
		if (Array.isArray(val)) {
			const value = selectedKey === 'root' ? `${key}` : `${selectedKey}.${key}`
			onSelectVariable?.(`{{#each ${value}}} {{this}} {{/each}}`)
			return
		}
		const value = selectedKey === 'root' ? `{{${key}}}` : `{{${selectedKey}.${key}}}`

		onSelectVariable?.(value)
	}

	const selectedGroup = groups[selectedKey] ?? {}
	const selectedGroupKeys = flatKeys(selectedGroup, ['team']).filter((key) => {
		// const selectedGroupKeys = Object.keys(selectedGroup).filter((key) => {
		if (!needle) {
			return true
		}
		return key.includes(needle)
	})

	const handleCopyKey = (e: React.MouseEvent<HTMLDivElement>, key: string) => {
		e.preventDefault()
		e.stopPropagation()

		const value = selectedKey === 'root' ? `{{${key}}}` : `{{${selectedKey}.${key}}}`
		// copy key to clipboard
		navigator.clipboard.writeText(value)
		toast.success(`Copied ${value} to clipboard`, {
			autoClose: 500,
		})
	}

	return (
		<div className="flex flex-col w-full space-y-2 h-1/2">
			<div className="w-full overflow-x-scroll">
				<Button.Group>
					<Button flat={selectedKey !== 'root'} onClick={() => setSelectedKey('root')}>
						root
					</Button>
					{topLevelKeys.map((key) => {
						const val = groups[key]
						if (isEmpty(val)) return
						return (
							<Button
								key={key}
								flat={selectedKey !== key}
								onClick={() => setSelectedKey(key)}
							>
								{key}
							</Button>
						)
					})}
				</Button.Group>
			</div>

			<div className="flex px-2">
				<Input
					aria-label="search variable"
					bordered
					fullWidth
					contentLeft={<HiSearch />}
					onInput={(e) => setNeedle(e.currentTarget.value)}
				/>
			</div>
			<div className="flex w-full h-full flex-col overflow-auto overflow-x-visible overflow-y-scroll">
				{selectedGroupKeys.map((key) => {
					const value = valueFromKey(selectedGroup, key)
					const vt = typeof value
					const isArray = Array.isArray(value)

					const valueToComponent = (value: any) => {
						const type = typeof value

						if (value === undefined || value === null) {
							return <StringValue value={'None'} />
						}

						switch (type) {
							case 'object':
								return <ObjectValue value={value} />
							default:
								return <StringValue value={value} />
						}
					}

					return (
						<div
							key={key}
							className={clsx('flex w-full pl-2 pr-3 items-start p-2 space-x-2', {
								'cursor-pointer': !!onSelectVariable,
								'hover:bg-slate-100': !!onSelectVariable,
							})}
							onClick={() => handleSelectKey(key, value)}
						>
							<div className="flex w-1/3 text-sm items-center">
								<div
									className="flex p-2 hover:bg-slate-200 cursor-pointer rounded-md"
									onClick={(e) => handleCopyKey(e, key)}
								>
									<HiDuplicate />
								</div>
								&nbsp;{`{{${key}}}`}
							</div>

							<div className="grow w-1/3 text-sm justify-start max-h-[300px] overflow-y-scroll">
								{valueToComponent(value)}
							</div>
							<div className="w-auto text-sm justify-end">
								{isArray ? 'array' : vt}
							</div>
						</div>
					)
				})}
			</div>
		</div>
	)
}

function valueFromKey(obj: Record<string, any>, key: string) {
	const parts = key.split('.')
	let value = obj
	for (const part of parts) {
		value = value[part]
	}
	return value
}

function flatKeys(obj: Record<string, any>, keysToFlat?: string[], prefix = ''): string[] {
	const keys: string[] = []
	for (const key in obj) {
		const value = obj[key]
		if (keysToFlat && typeof value === 'object' && keysToFlat.includes(key)) {
			const nestedKeys = flatKeys(value, keysToFlat, `${prefix}${key}.`)
			keys.push(...nestedKeys)
		} else {
			keys.push(`${prefix}${key}`)
		}
	}
	return keys
}

export function isConditional(text: string) {
	const t = text.trim().slice(-20)
	console.log(t.endsWith('#if'))
	console.log(t.endsWith('#unless'))
	console.log(t.endsWith('#each'))
	console.log(t.endsWith('#with'))

	return t.endsWith('#if') || t.endsWith('#unless') || t.endsWith('#each') || t.endsWith('#with')
}
